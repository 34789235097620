import en from "../local/en_us"
import den from "../local/ge"
import am from "../local/am"
const translations = {
    en: {
      translation: en,
    },
    am: {
      translation: am,
    },
    de: {
      translation: den,
    },
  };
  export default translations;