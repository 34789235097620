import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal} from 'react-bootstrap';
const 
 SharedContentModal = ({
  show,
  title,
  onHide,
  centered = true,
  backdrop = 'static',
  keyboard = false,
  content,
}) => {
  const handleCloseModal = () => {
    onHide();
  };
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={handleCloseModal} centered={centered} backdrop={backdrop} keyboard={keyboard}>
      <Modal.Header closeButton>
        <Modal.Title>{t(title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};


export default SharedContentModal;
