import { configureStore } from '@reduxjs/toolkit';
import persistedAuthReducer from './authentication/authSlice';
import { persistStore } from 'redux-persist';
import modeReducer from './mode/index'
const store= configureStore({
    reducer: {
      mode: modeReducer,
      auth: persistedAuthReducer
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  });

  
const persistor = persistStore(store);

export { store, persistor };
