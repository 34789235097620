import {fetchData,postData,updateData } from '../utils/apiUtils';

export const get_project_data = async () => {
    const responseData = await fetchData('project');
    return responseData;
  };
  export const get_project_data_byId = async (id) => {
    const responseData = await fetchData(`project/byid?id=${encodeURIComponent(id)}`);
    return responseData;
    };
export const get_project_jobs = async () => {
    const responseData = await fetchData('project/project_job');
    return responseData;
  };
  export const RegisterProject = async (projectData) => {
    const responseData = await postData('project/register', projectData);
    return responseData;
  };

  export const AssignProjectLabor = async (projectData) => {
    const responseData = await postData('project/project_labor/register', projectData);
    return responseData;
  };


  export const get_project_labors = async (project) => {
    const responseData = await fetchData(`project/project_labor/byproject?project=${encodeURIComponent(project)}`);
    return responseData;
  };


  export const get_project_attendance_sheet = async (project) => {
    const responseData = await fetchData(`project/labor_attendance/sheet?project=${encodeURIComponent(project)}`);
    return responseData;
  };

  export const get_project_attendance_sheet_bydate = async (project,from,to) => {
    const responseData = await fetchData(`project/labor_attendance/findsheet/byproject?project=${encodeURIComponent(project)}&fromdate=${encodeURIComponent(from)}&todate=${encodeURIComponent(to)}`);
    return responseData;
  };


  export const get_labor_attendance = async (project,date) => {
    const responseData = await fetchData(`project/labor_attendance/byproject?project=${encodeURIComponent(project)}&date=${encodeURIComponent(date)}`);
    return responseData;
  };
  
  export const CreateBulkAtendanceSheet = async (projectData) => {
    const responseData = await postData('project/labor_attendance/bulkregister', projectData);
    return responseData;
  };
  export const UpdateAtendance= async (AttendanceData) => {
    const responseData = await updateData('project/labor_attendance/update', AttendanceData);
    return responseData;
  };
  
  export const UpdateBulkAtendance= async (AttendanceData) => {
    const responseData = await updateData('project/labor_attendance/bulkupdate', AttendanceData);
    return responseData;
  };