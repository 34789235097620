// validation.js
export const validateForm = (formData) => {
    const errors = {};
    if (!formData.first_name.trim()) {
      errors.first_name = "First Name is required";
    }
  
    if (!formData.father_name.trim()) {
      errors.father_name = "Father Name is required";
    }
    if (!formData.last_name.trim()) {
        errors.last_name = "Last Name is required";
      }
      
      if (!formData.hire_date) {
        errors.hire_date = "Employement date is required";
      } else if (!isValidDate(formData.hire_date)) {
        errors.hire_date = "Invalid employement start date format";
      }

      if (!formData.birth_date) {
        errors.birth_date = "Birth Date is required";
      } else if (!isValidDate(formData.birth_date)) {
        errors.birth_date = "Invalid birth date format";
      } else {
        const currentDate = new Date();
        const inputDate = new Date(formData.birth_date);
      
        if (inputDate > currentDate) {
          errors.birth_date = "Invalid date of birth (future date)";
        }
    
        const dateDifference = Math.abs(currentDate.getFullYear() - inputDate.getFullYear());
        if (dateDifference < 10 || dateDifference > 150) {
          errors.birth_date = "Invalid date of birth";
        }
      }
      

    if (formData.email.trim()) {
       if (!isValidEmail(formData.email)) {
       errors.email = "Invalid email format";
    }
  }
  
    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!isValidPhone(formData.phone)) {
      errors.phone = "Invalid phone number format";
    }

    if (!formData.address.country.trim()) {
        errors.country = "Country Name is required";
      }

      if (!formData.address.region.trim()) {
         errors.region = "Region Name is required";
      }
      if (!formData.address.zone.trim()) {
        errors.zone= "Kifle ketema Name is required";
      }
      if (!formData.address.wereda.trim()) {
        errors.wereda = "Wereda Name is required";
      }

      if (!formData.address.kebele.trim()) {
        errors.kebele = "Kebele is required";
      }
    return errors;
  };
  
  export const isValidEmail = (email) => {
    // Basic email validation regex, you can enhance it
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  export const isValidPhone = (phone) => {
    // Basic phone number validation regex, you can enhance it
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };
  
  export const isValidDate = (date) => {
    // Basic date validation regex, you can enhance it
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(date);
  };