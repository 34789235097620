import React, { useState} from "react";
import { useTranslation } from "react-i18next";
import {useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Button,Badge,Modal,Spinner} from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import {useQuery,useMutation,useQueryClient } from "react-query";
import { get_labor_attendance,UpdateAtendance,UpdateBulkAtendance} from "../../../services/projectApi";
import { formatDate } from '../../../utils/date_utils';
const Attendance_list_view = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const acceptedParameter = location.state?.data;
  const [selectedProject] = useState(acceptedParameter.project.id);
  const [selectedDate, setSelectedDate] = useState(acceptedParameter.date);
  const [editableRow, setEditableRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const queryClient = useQueryClient();

  const registrationMutation = useMutation(async (newData) => {
    await UpdateAtendance(newData);
    queryClient.invalidateQueries('labor_attendance_edit');
  });

  const bulkUpdateMutation = useMutation(async (newData) => {
    await UpdateBulkAtendance(newData);
    queryClient.invalidateQueries('labor_attendance_edit');
  });
  const LaborattendanceQuery = useQuery(['labor_attendance_edit', selectedProject, selectedDate],() => get_labor_attendance(selectedProject, selectedDate));
  if (LaborattendanceQuery.isLoading) {
    return <div>Loading...</div>;
  }
  if (LaborattendanceQuery.isError) {
    return <div>Error: {LaborattendanceQuery.error.message}</div>;
  }
  const labor_attendance= LaborattendanceQuery.data;

  const handleAddLabor = async () => {
    const data = {
      project: acceptedParameter.project,
      date: selectedDate
    }
    history.push('/add-labors-to-project', {data});
  };
  const handleEdit = (id) => {
    setEditableRow(id);
    setEditedData((prevData) => ({
      ...prevData,
      [id]: {
        morning: labor_attendance.find(row => row.id === id)?.morning,
        afternoon: labor_attendance.find(row => row.id === id)?.afternoon,
        holiday: labor_attendance.find(row => row.id === id)?.holiday,
        over_time: labor_attendance.find(row => row.id === id)?.over_time

      },
    }));
  };
  const handleEditChange = (id, field, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [id]: {
        ...prevData[id],
        [field]: value === '' ? undefined : value,
      },
    }));
  };

  const handleUpdate = async(id) => {
    const data=editedData[id];
    const newData = { ...data, id: id};
    setIsSubmitting(true);
    try {
      await registrationMutation.mutate(newData);
      toast.success('Attendance updated successfully', {
        position: 'top-right',
        autoClose: 4000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
       
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 6000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setEditableRow(null);
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async() => {
    handleClose();
  if(labor_attendance.length===0){
    toast.error('Empty attendance recored list', {
          position: 'top-right',
          autoClose: 4000, 
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
  })
  return 0;
}

  const newdata = labor_attendance.map((item) => ({
    id: item.id,
    status: false,
    current_status: "submitted"
  }));
  setIsSubmitting(true);
try {
      await bulkUpdateMutation.mutate(newdata);
      toast.success('Attendance submitted successfully', {
        position: 'top-right',
        autoClose: 4000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
       
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 6000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const Attendancesumation=(morning,afternoon,overtime,holiday)=>{
    const result =morning+afternoon+overtime+holiday;
    const roundedResult = result.toFixed(2);
      return roundedResult
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="12">
                <Card className="card-block card-stretch">
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h6 className="font-weight-bold"> {acceptedParameter.project.name} project {t("labor_attendance")} on {formatDate(selectedDate)} </h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <input type="date" value={selectedDate}
                         className="form-select form-control-sm m-2"
                         onChange={(e) => setSelectedDate(new Date(e.target.value).toISOString())}
                         />
                     
                        {labor_attendance.length>0 && labor_attendance[0].status!==false?(
                        <>
                        <Button variant="btn btn-info btn-sm m-2" onClick={handleAddLabor}>
                        {t("add_labor")}
                       </Button>

                        <Button variant="btn btn-success btn-sm m-2" onClick={handleShow}>
                         {t("submit")}
                        </Button>
                        </>
                        ):
                        ("")}

                      <Link to="/labor-attendance-sheet" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">{t("back")}</span>
                    </Link> 
                    </div> 
                    </div>
                    <div className="table-responsive" size="sm">
                      <table className="table data-table mb-0">
                        <thead>
                          <tr className="table-primary">
                            <th scope="col" className="w-01 pr-0">
                              #
                            </th>
                            <th scope="col">
                              <label className="m-0" >{t("full_name")}</label>
                            </th>
                            <th scope="col">
                              <label className="mb-0">{t("morning")}</label>
                            </th>
                            <th scope="col">
                              <label className="mb-0">{t("afternoon")}</label>

                            </th>
                            <th scope="col">
                              <label className="mb-0" >{t("over_time")}</label>
                            </th>
                            <th scope="col" className="dates">
                              <label className="mb-0" >{t("holiyday")}</label>
                            </th>
                            <th scope="col" className="dates">
                              <label className="mb-0" >{t("total")}</label>
                            </th>
                            <th scope="col" className="text-right">
                              <span className="text-muted" >Action</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {labor_attendance.length === 0 ? (
                          <tr>
                          <td colSpan="9" className="text-center"> There are no records found on this day. Please select a different day</td>
                        </tr>
                        ):(
                          labor_attendance.map((item, index) => (
                            <tr key={item.cust} className="white-space-no-wrap">
                              <td className="pr-0">
                                {index + 1}
                              </td>
                              <td>
                                {item.labor.first_name} {item.labor.father_name}
                              </td>
                              <td>
                                {editableRow === item.id ? (
                                  <input
                                  type="number"
                                  required
                                  step="0.1"
                                  className="form-select form-control-sm m-2"
                                  max={4}
                                  min={0}
                                    value={editedData[item.id]?.morning !== undefined ? editedData[item.id]?.morning : ''}
                                    onChange={(e) =>
                                      handleEditChange(item.id, 'morning', e.target.value)
                                    }
                                    style={{ width: '80px' }}
                                  />
                                ) : (
                                  item.morning.toFixed(2)
                                )}
                              </td>
                              <td >
                                {editableRow === item.id ? (
                                  <input
                                  type="number"
                                  required
                                  step="0.1"
                                  className="form-select form-control-sm m-2"
                                  max={4}
                                  min={0}
                                    value={editedData[item.id]?.afternoon !== undefined ? editedData[item.id]?.afternoon : ''}
                                    onChange={(e) =>
                                      handleEditChange(item.id, 'afternoon', e.target.value)
                                    }
                                    style={{ width: '80px' }}
                                  />
                                ) : (
                                  item.afternoon.toFixed(2)
                                )}
                              </td>
                              <td>

                                {editableRow === item.id ? (
                                  <input
                                   
                                    type="number"
                                    required
                                    step="0.1"
                                    className="form-select form-control-sm m-2"
                                    max={12}
                                    min={0}
                                    value={editedData[item.id]?.over_time !== undefined ? editedData[item.id]?.over_time : ''}
                                    onChange={(e) =>
                                      handleEditChange(item.id, 'over_time', e.target.value)
                                    }
                                    style={{ width: '80px' }}
                                  />
                                ) : (
                                  item.over_time.toFixed(2)
                                )}
                              </td>
                              <td>
                                {editableRow === item.id ? (
                                  <input
                                    type="number"
                                    required
                                    step="0.1"
                                    className="form-select form-control-sm m-2"
                                    max={12}
                                    min={0}
                                    value={editedData[item.id]?.holiday !== undefined ? editedData[item.id]?.holiday : ''}
                                    onChange={(e) =>
                                      handleEditChange(item.id, 'holiday', e.target.value)
                                    }
                                    style={{ width: '80px' }}
                                  />
                                ) : (
                                  item.holiday.toFixed(2)
                                )}
                              </td>
                              <td>
                                {Attendancesumation(item.morning,item.afternoon,item.over_time,item.holiday)}
                              </td>
                              <td className="text-right">
                                {item.status===true? (
                                editableRow === item.id ? (
                                  <>
                                    <Button size="sm" variant="success mr-2" onClick={() => handleUpdate(item.id)} disabled={isSubmitting}>
                      {isSubmitting ? (
                        <span>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Sending...</span>
                        </span>
                      ) : (
                        t("update")
                      )}  </Button>
                                    <Button size="sm" variant="secondary mr-2" onClick={() => setEditableRow(null)}>Cancel</Button>
                                  </>
                                ) : (
                                  <Button variant="btn" onClick={() => handleEdit(item.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                    </svg>
                                  </Button>
                                )
                                ):
                                (<>
                                 <Badge variant="info">{item.current_status}</Badge>
                                 </>)  
                             }
                              </td>
                            </tr>
                          ))
                        )
                          }
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                                 <Modal.Title as="h5">Submit Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        Are you sure you want to submit the attendance record?
                        </Modal.Body>
                        <Modal.Footer>
                           <Button variant="info" onClick={handleClose}>No, cancel the action</Button>
                           <Button variant="success" onClick={handleSubmit}>Yes, I'm sure.</Button>
                        </Modal.Footer>
                     </Modal>
    </>
  )
}
export default Attendance_list_view;