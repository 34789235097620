import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const AlertModal = ({ show, onHide, title, message }) => {
  const titleColor = title.toLowerCase().includes('error') ? 'red' : 'green'; // Example logic for color selection

  return (
    <Modal show={show} onHide={onHide} centered={true} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title style={{color: titleColor }}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{color: titleColor }}>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
