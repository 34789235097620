import React from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import  Card from '../../../../components/Card'

const PrivacyPolicy = () => {
    return (
        <>
           <Container fluid>
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">What is B Square Y Engineering ERP System?</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>B Square Y Engineering was established in a year 2018 G.C and registered as a private company in a democratic republic of Ethiopia as General Contractor firm which is an Addis Ababa based General contractor Company. We specialize in building, road, landscape, electromechanical, and water work constructions. Our expertise spans a wide range from pre-construction services to complete turn-key construction projects. 
Integrity, quality, and innovation are the characteristics that distinguish B Square Y Engineering as a leading construction company. Our goal is to provide a higher level of service than is found with any other company in the construction industry.
Our successful construction approach is based on implementing a modern project management system on every project, because scheduling and budget concerns are important to us. We employ only qualified workers who share our goal in creating a quality cost-competitive project.
</p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">Why do we use it?</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Enterprise Resource Planning (ERP) systems are essential for organizations as they integrate and manage various business processes across departments. The use of ERP systems offers benefits such as integrated information, increased efficiency and productivity, improved decision-making through real-time data access, cost reduction, enhanced customer satisfaction, compliance with regulations, optimized supply chain management, scalability, standardized processes, and improved collaboration. ERP systems contribute to business intelligence, enabling organizations to analyze trends and key performance indicators. In summary, ERP systems provide a centralized and streamlined approach to managing business operations, fostering agility, competitiveness, and adaptability to changing environments. </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">Where does it come from?</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Enterprise Resource Planning (ERP) systems are essential for organizations as they integrate and manage various business processes across departments. The use of ERP systems offers benefits such as integrated information, increased efficiency and productivity, improved decision-making through real-time data access, cost reduction, enhanced customer satisfaction, compliance with regulations, optimized supply chain management, scalability, standardized processes, and improved collaboration. ERP systems contribute to business intelligence, enabling organizations to analyze trends and key performance indicators. In summary, ERP systems provide a centralized and streamlined approach to managing business operations, fostering agility, competitiveness, and adaptability to changing environments.</p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">Where can I get some?</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Enterprise Resource Planning (ERP) systems are essential for organizations as they integrate and manage various business processes across departments. The use of ERP systems offers benefits such as integrated information, increased efficiency and productivity, improved decision-making through real-time data access, cost reduction, enhanced customer satisfaction, compliance with regulations, optimized supply chain management, scalability, standardized processes, and improved collaboration. ERP systems contribute to business intelligence, enabling organizations to analyze trends and key performance indicators. In summary, ERP systems provide a centralized and streamlined approach to managing business operations, fostering agility, competitiveness, and adaptability to changing environments.</p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">Why do we use it?</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Enterprise Resource Planning (ERP) systems are essential for organizations as they integrate and manage various business processes across departments. The use of ERP systems offers benefits such as integrated information, increased efficiency and productivity, improved decision-making through real-time data access, cost reduction, enhanced customer satisfaction, compliance with regulations, optimized supply chain management, scalability, standardized processes, and improved collaboration. ERP systems contribute to business intelligence, enabling organizations to analyze trends and key performance indicators. In summary, ERP systems provide a centralized and streamlined approach to managing business operations, fostering agility, competitiveness, and adaptability to changing environments.</p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">Why do we use it?</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Enterprise Resource Planning (ERP) systems are essential for organizations as they integrate and manage various business processes across departments. The use of ERP systems offers benefits such as integrated information, increased efficiency and productivity, improved decision-making through real-time data access, cost reduction, enhanced customer satisfaction, compliance with regulations, optimized supply chain management, scalability, standardized processes, and improved collaboration. ERP systems contribute to business intelligence, enabling organizations to analyze trends and key performance indicators. In summary, ERP systems provide a centralized and streamlined approach to managing business operations, fostering agility, competitiveness, and adaptability to changing environments.</p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">Why do we use it?</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Enterprise Resource Planning (ERP) systems are essential for organizations as they integrate and manage various business processes across departments. The use of ERP systems offers benefits such as integrated information, increased efficiency and productivity, improved decision-making through real-time data access, cost reduction, enhanced customer satisfaction, compliance with regulations, optimized supply chain management, scalability, standardized processes, and improved collaboration. ERP systems contribute to business intelligence, enabling organizations to analyze trends and key performance indicators. In summary, ERP systems provide a centralized and streamlined approach to managing business operations, fostering agility, competitiveness, and adaptability to changing environments.</p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">Why do we use it?</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Enterprise Resource Planning (ERP) systems are essential for organizations as they integrate and manage various business processes across departments. The use of ERP systems offers benefits such as integrated information, increased efficiency and productivity, improved decision-making through real-time data access, cost reduction, enhanced customer satisfaction, compliance with regulations, optimized supply chain management, scalability, standardized processes, and improved collaboration. ERP systems contribute to business intelligence, enabling organizations to analyze trends and key performance indicators. In summary, ERP systems provide a centralized and streamlined approach to managing business operations, fostering agility, competitiveness, and adaptability to changing environments.</p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">Why do we use it?</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <p>Enterprise Resource Planning (ERP) systems are essential for organizations as they integrate and manage various business processes across departments. The use of ERP systems offers benefits such as integrated information, increased efficiency and productivity, improved decision-making through real-time data access, cost reduction, enhanced customer satisfaction, compliance with regulations, optimized supply chain management, scalability, standardized processes, and improved collaboration. ERP systems contribute to business intelligence, enabling organizations to analyze trends and key performance indicators. In summary, ERP systems provide a centralized and streamlined approach to managing business operations, fostering agility, competitiveness, and adaptability to changing environments. </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default PrivacyPolicy;