import React from 'react'
import {Container,Row,Col,Accordion,Button } from 'react-bootstrap'
import  Card from '../../../../components/Card'


const TermsOfUse = () => {
    return (
        <>
        <Container fluid id="faqAccordion">
            <Row>
                <Col lg="12">
                    <Accordion className="mm-accordion career-style faq-style" defaultActiveKey="0">
                        <Card className="mm-accordion-block accordion">
                            <div className="active-faq clearfix" id="headingOne">
                                <Container fluid>
                                    <Row>
                                        <Col sm="12">
                                            <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="0">
                                                <span>General </span>
                                            </Accordion.Toggle>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Accordion.Collapse eventKey="0" className="accordion-details">
                                <p className="mb-0">B Square Y Engineering was established in a year 2018 G.C and registered as a private company in a democratic republic of Ethiopia as General Contractor firm which is an Addis Ababa based General contractor Company. We specialize in building, road, landscape, electromechanical, and water work constructions. Our expertise spans a wide range from pre-construction services to complete turn-key construction projects.  </p>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="mm-accordion-block">
                            <div className="active-faq clearfix" id="headingTwo">
                                <Container fluid>
                                    <Row>
                                        <Col sm="12">
                                            <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="1">
                                                <span>Our Visions</span>
                                            </Accordion.Toggle>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Accordion.Collapse className="accordion-details" eventKey="1">
                                <p className="mb-0">B Square Y Engineering to become the leading construction firm, while delivering projects that consistently exceed international standards and provide exceptional customer satisfaction.
To continually deliver excellent value & innovative construction solutions to meet our clients' requirements.
Using modern principles and sophisticated technologies, Alamiah Building Company envisions being the primary preference at all times both nationally and globally, for their renowned excellence, quality, performance and reliability in all types of constructions

                                </p>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="mm-accordion-block ">
                            <div className="active-faq clearfix" id="headingThree">
                                <Container fluid>
                                    <Row>
                                        <Col sm="12">
                                            <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="2">
                                                <span>Our Mission Statement</span>
                                            </Accordion.Toggle>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Accordion.Collapse className="accordion-details" eventKey="2">
                                <p className="mb-0">To be a leading construction company in the global market.
To become the customers' most preferred choice by attaining excellence in quality and timely completed value added projects.
To provide the highest level of service in the construction industry while offering superior craftsmanship to every project, we handle.
To continually innovate, develop and adopt state-of-the-art technology in methods and materials to enhance productivity and cost effectiveness.
To continually improve the competence of our team, and employ diverse, innovative & results-oriented personals, motivated to deliver excellence.
To build a safety culture aimed at continually reducing the frequency severity rate towards achieving zero accidents.with applicable environmental norms

                                </p>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="mm-accordion-block ">
                            <div className="active-faq clearfix" id="headingFour">
                                <Container fluid>
                                    <Row>
                                        <Col sm="12">
                                            <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="3">
                                                <span>Objective</span>
                                            </Accordion.Toggle>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Accordion.Collapse className="accordion-details"  eventKey="3">
                                <p className="mb-0">B Square Y Engineering aims to provide the highest possible standard of quality and service in the construction industry, giving Clients the best satisfaction, the company can offer. THE BEST RESOURCES AND LATEST TECHNOLOGY
                                </p>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="mm-accordion-block">
                            <div className="active-faq clearfix" id="headingFive">
                                <Container fluid>
                                    <Row>
                                        <Col sm="12">
                                            <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="4">
                                                <span>Philosophy</span>
                                            </Accordion.Toggle>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Accordion.Collapse className="accordion-details" eventKey="4">
                                <p className="mb-0">A commitment to Responsiveness
           There's only one thing greater than our passion for building. And that's our dedication to the needs and desires of our customers. While cost is always important, we know that it's only one reason for choosing us to be your general contractor. You're also looking for sound project management, efficient coordination, and construction of the highest quality. 
So, we make it our business to thoroughly understand your business. For example, there may be one part of your construction project that you absolutely need to have completed before any other - if your operations are to keep running smoothly. No problem. Chances are, we already identified it well in advance and scheduled our construction accordingly. You see, B Square Y Engineering, we build to suit you, not to suit us.

                                </p>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="mm-accordion-block">
                            <div className="active-faq clearfix" id="headingSix">
                                <Container fluid>
                                    <Row>
                                        <Col sm="12">
                                            <Accordion.Toggle as={Button} href="#"   bsPrefix="accordion-title" eventKey="5">
                                                <span>Security & Safety</span>
                                            </Accordion.Toggle>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Accordion.Collapse className="accordion-details" eventKey="5">
                                <p className="mb-0">The manpower of B Square Y Engineering is its most valuable resource. To ensure a safe workplace for B Square Y’s staff and to protect clients’ investment and facilities, the Group has evolved a strong health and safety culture driven by a goal to achieve “zero accidents” and “zero man-hours lost”. The safety culture has been instilled in B Square Y employees with the conviction that every accident is preventable. The company fully acknowledges that by 
                                complying with stringent Health, Safety and Environment (HSE) procedures, zero accidents can be accomplished. SOME OF OUR HSE PROCEDURES ARE • Use of full range of Personal Protective Equipment (PPE) • Use of Safe Tools & Equipment • Trained & competent staff • Regular on-site safety audits ZERO ACCIDENTS AND ZERO MAN HOURS LOST 
                                </p>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default TermsOfUse;