import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import Scrollbar from "smooth-scrollbar";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDarkMode } from "../../../../store/mode";
import menuData from "./data";
//img
import logo from "../../../../../src/assets/images/logo.png";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const minisidbar = () => {
  document.body.classList.toggle("sidebar-main");
};
const SidebarStyle = (props) => {
  //location
  let location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const sidebar = urlParams.get("sidebar");
  var variant = "";
  if (sidebar !== null) {
    variant = "";
    switch (sidebar) {
      case "0":
        variant = "sidebar-dark";
        break;
      case "1":
        variant = "sidebar-light";
        break;
      default:
        variant = "";
        break;
    }
  }
  // Collapse state
  const [activeMenu, setActiveMenu] = useState(false);
  // const [activesubMenu, setSubmenu] = useState(false);
  useEffect(() => {
    Scrollbar.init(document.querySelector("#my-scrollbar"));
  });
  const { t } = useTranslation();
  const MenuItem = ({ to, icon, label, isActive, subMenu }) => {
    const hasSubMenu = subMenu && subMenu.length > 0;
    if (hasSubMenu) {
      return (
        <li className={`${isActive ? "active" : ""}  sidebar-layout`}>
          <Accordion.Toggle
            as={Button}
            href="#"
            eventKey={to}
            variant="collapsed"
            data-toggle="collapse"
            aria-expanded={activeMenu === to ? "true" : "false"}
          >
            <i>{icon}</i>
            <span className="ml-2">{t(label)}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="svg-icon iq-arrow-right arrow-active"
              width="15"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Accordion.Toggle>
          <Accordion.Collapse className="submenu" eventKey={to}>
            <ul className="submenu" data-parent="#iq-sidebar-toggle">
              {subMenu.map((subMenuItem, index) => (
                <MenuItem key={index} {...subMenuItem} />
              ))}
            </ul>
          </Accordion.Collapse>
        </li>
      );
    } else {
      return (
        <li className={`${isActive ? "active" : ""}  sidebar-layout`}>
          <Link to={to} className="svg-icon">
            <i>{icon}</i>
            <span className="ml-2">{t(label)}</span>
          </Link>
        </li>
      );
    }

  };
  return (
    <>
      <div className={`iq-sidebar sidebar-default ${variant}`}>
        <div className="iq-sidebar-logo d-flex align-items-end justify-content-between">
          <Link to="/" className="header-logo">
            <img
              src={logo}
              className={`img-fluid rounded-normal light-logo ${
                props.darkMode ? "d-none" : ""
              }`}
              alt="logo"
            />
            <span>{t("org_title")}</span>
          </Link>
          <div className="side-menu-bt-sidebar-1">
            <svg
              onClick={minisidbar}
              xmlns="http://www.w3.org/2000/svg"
              className="text-light wrapper-menu"
              width="30"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
          <nav className="iq-sidebar-menu">
            <Accordion
              as="ul"
              id="iq-sidebar-toggle"
              className="side-menu"
              onSelect={(e) => setActiveMenu(e)}
            >

                 {/* part one  */}
              {menuData.map((group, index) => (
                
                   <React.Fragment key={index}>
                  <li className="px-3 pt-3 pb-2">
                    <span className="text-uppercase small font-weight-bold">
                      {group.header}
                    </span>
                  </li>
                  {group.items.map((menuItem, index) => (
                    <MenuItem
                      key={index}
                      isActive={menuItem.to === location.pathname}
                      {...menuItem}
                    />
                  ))}
                </React.Fragment>
              ))}
            </Accordion>
          </nav>
          <div className="pt-5 pb-5"></div>
        </div>
      </div>
    </>
  );
};

// export default SidebarStyle;
export default connect(mapStateToProps)(SidebarStyle);
