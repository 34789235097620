import React from 'react' 
import { useTranslation} from 'react-i18next';
const lngs = {
  en: { nativeName: 'English' },
  am: { nativeName: 'አማርኛ' },
};
const SelectLanguage = () => {
    const {i18n } = useTranslation();
    const handleLanguageChange = (lng) => {
      i18n.changeLanguage(lng);
    };
    return (
        <>
         <div>
          <select
            value={i18n.language}
            onChange={(e) => handleLanguageChange(e.target.value)}
          >
            {Object.keys(lngs).map((lng) => (
              <option key={lng} value={lng}>
                {lngs[lng].nativeName}
              </option>
            ))}
          </select>
        </div>
        {/* <div className=" p-0 ">
                                        <ul className="dropdown-menu-1 list-group list-group-flush">
                                            <li className="dropdown-item-1 list-group-item  px-2"><Link className="p-0" to="#"><img src={flag3} alt="img-flaf" className="img-fluid mr-2" width= "15px" height= "15px" min-width= "15px"/>Spanish</Link></li>
                                            <li className="dropdown-item-1 list-group-item  px-2"><Link className="p-0" to="#"><img src={flag3} alt="img-flaf" className="img-fluid mr-2" width= "15px" height= "15px" min-width= "15px"/>Italian</Link></li>
                                            <li className="dropdown-item-1 list-group-item  px-2"><Link className="p-0" to="#"><img src={flag3} alt="img-flaf" className="img-fluid mr-2" width= "15px" height= "15px" min-width= "15px"/>French</Link></li>
                                            <li className="dropdown-item-1 list-group-item  px-2"><Link className="p-0" to="#"><img src={flag3} alt="img-flaf" className="img-fluid mr-2" width= "15px" height= "15px" min-width= "15px"/>German</Link></li>
                                            <li className="dropdown-item-1 list-group-item  px-2"><Link className="p-0" to="#"><img src={flag3} alt="img-flaf" className="img-fluid mr-2" width= "15px" height= "15px" min-width= "15px"/>Japanese</Link></li>
                                        </ul>
                                    </div> */}

        </>
    )
}

export default SelectLanguage;