import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import { validateForm } from "../../../middleware/validateLaborForm";
import { RegisterLabor  } from "../../../services/employeeApi";
import AlertModal from "../../sharedComponent/AlertModal";
import {toast} from 'react-toastify';
const Laboradd = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    first_name: "",
    father_name: "",
    last_name: "",
    sex: "male",
    phone: "",
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const handleInputChange = (e, field, nestedField) => {
    const value = nestedField
      ? { ...formData[field], [nestedField]: e.target.value }
      : e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
    // Clear the validation error for the field when the value changes
    setValidationErrors({
      ...validationErrors,
      [field]: "",
    });
  };
 
  const registrationMutation = useMutation( RegisterLabor );
  const handleRegistration = async (employeeData) => {
    try {
      setIsSubmitting(true);
      await registrationMutation.mutateAsync(employeeData);
      toast.success('New labor registered successfully', {
        position: 'top-right',
        autoClose: 4000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      setshowAlert(true);
      setalertTitle("Error");
      setAlertMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      handleRegistration(formData);
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/labor">{t("labor")}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("add_labor")}
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to="/labor"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">{t("back")}</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              {" "}
              {t("add_new_labor")}
            </h4>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Form
                      className="row g-3 date-icon-set-modal"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("first_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter first Name"
                          value={formData.first_name}
                          onChange={(e) => handleInputChange(e, "first_name")}
                        />
                        {validationErrors.first_name && (
                          <div className="text-danger">
                            {validationErrors.first_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("middle_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter middle Name"
                          value={formData.father_name}
                          onChange={(e) => handleInputChange(e, "father_name")}
                        />
                        {validationErrors.father_name && (
                          <div className="text-danger">
                            {validationErrors.father_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("last_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter last Name"
                          value={formData.last_name}
                          onChange={(e) => handleInputChange(e, "last_name")}
                        />
                        {validationErrors.last_name && (
                          <div className="text-danger">
                            {validationErrors.last_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label className="font-weight-bold text-muted ">
                          {t("sex")}
                        </Form.Label>
                        <br />
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio custom-control-inline">
                            <Form.Control
                              type="radio"
                              id="inlineRadio1"
                              name="customRadio-1"
                              className="custom-control-input"
                              value="male"
                              checked={formData.sex === "male"}
                              onChange={(e) => handleInputChange(e, "sex")}
                            />
                            <Form.Label
                              className="custom-control-label"
                              htmlFor="inlineRadio1"
                            >
                              {t("male")}
                            </Form.Label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio custom-control-inline">
                            <Form.Control
                              type="radio"
                              id="inlineRadio2"
                              name="customRadio-1"
                              className="custom-control-input"
                              value="female"
                              checked={formData.sex === "female"}
                              onChange={(e) => handleInputChange(e, "sex")}
                            />
                            <Form.Label
                              className="custom-control-label"
                              htmlFor="inlineRadio2"
                            >
                              {t("female")}
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("phone")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone"
                          value={formData.phone}
                          onChange={(e) => handleInputChange(e, "phone")}
                        />
                        {validationErrors.phone && (
                          <div className="text-danger">
                            {validationErrors.phone}
                          </div>
                        )}
                      </div>
                    </Form>
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Sending...</span>
                          </span>
                        ) : (
                          t("add_labor")
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Laboradd;
