import  React from 'react'
import {useTranslation } from "react-i18next";
import {useLocation } from 'react-router-dom';
import {Container,Row,Col,Button} from 'react-bootstrap'
import  Card from '../../../components/Card'
import {Link} from 'react-router-dom'
import {get_employeeReference} from "../../../services/employeeApi";
import {useQuery } from "react-query";
const EmployeeReference =()=>{
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const { data, isLoading, isError} = useQuery(["emp_experience", id], async () => {
    const [employee] = await Promise.all([
      get_employeeReference(id)
    ]);
    return { employee };
  });
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  if (isError) {
    return <div>Error: Unable to fetch employee reference data. Please try again later.</div>;
  }
  
  const {employee} = data || {};
  
    return(
        <>
           <Container fluid>
        <Row>            
            <Col lg="12">

            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/employee">{t("employee")}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`employee-view/?id=${id}`}>{t("employee_view")}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("employee_reference")}
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to={`employee-view/?id=${id}`}
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">{t("back")}</span>
              </Link>
            </div>
            </Col>
        </Row>
        <Row>
            <Col lg="12">
                <Card>
                    <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                        <h5>{t("reference_lists")}</h5>
                        <Link to={`/employee-reference-add?id=${id}`}>
                         <Button variant="btn btn-primary btn-sm">
                                        {t("add_new_ref")}
                                    </Button>
                                    </Link>
                                </div>
                                <div className="table-responsive">
                                    <table className="table data-table mb-0">
                                        <thead className="table-color-heading">
                                            <tr className="text-muted">
                                            <th scope="col">{t("id")}</th>
                                            <th scope="col">{t("name")} </th>
                                            <th scope="col">{t("reference_type")}</th>
                                            <th scope="col">{t("job")}</th>
                                            <th scope="col">{t("relationship")}</th>
                                            <th scope="col">{t("marital_status")}</th>
                                            <th scope="col">{t("is_inheritant")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {employee.map((item, rowCounter) => (
                            <tr key={item.cust} className="white-space-no-wrap">
                              <td className="pr-0">
                                <td>{rowCounter + 1}</td>
                              </td>
                              <td className="">
                                <div className="active-project-1 d-flex align-items-center mt-0 ">
                                  <div className="data-content">
                                    <div>
                                      <span className="font-weight-bold">
                                        {item.first_name} {item.father_name}
                                      </span>
                                    </div>
                                    <p className="m-0 text-secondary small">
                                      {item.phone}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>{t(item.reference_type)}</td>
                              <td>{item.job}</td>
                              <td>{item.relationship}</td>
                            
                              <td>
                                {item.marital_status}
                              </td>
                              <td>
                        <p className={`mb-0 ${item.color} font-weight-bold d-flex justify-content-start align-items-center`}>
                                  <small>
                                    <svg
                                      className="mr-2"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <circle
                                        cx="12"
                                        cy="12"
                                        r="8"
                                        fill="#3cb72c"
                                      ></circle>
                                    </svg>
                                  </small>
                                  {t(item.is_inheritant ? "Yes" : "Not ")}
                                </p>
                              </td>
                            </tr>
                          ))}                                               
                       </tbody>
                        </table>
                      </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
        </>
    )
}
export default EmployeeReference;