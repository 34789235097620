import  React ,{ useState } from 'react'
import {Container,Row,Col,ListGroup,Tab,Nav} from 'react-bootstrap'
import  Card from '../../../components/Card'
import {Link} from 'react-router-dom'
import Chart from "react-apexcharts";
import {get_project_labors,get_project_data_byId} from "../../../services/projectApi";
import {formatDate } from '../../../utils/date_utils';
import {useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useQuery} from "react-query";
import CustomPagination from "../../../utils/CustomPagination"
const Projectview =()=>{
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [currentPage, setCurrentPage] = useState(1);
      const { data, isLoading, isError, error } = useQuery(["project_labors", id], async () => {
        try {
        const [project_labor,project_data] = await Promise.all([
            get_project_labors(id),
            get_project_data_byId(id)
          ]);
        return { project_labor,project_data};
        } catch (error) {
          throw new Error(`Error fetching data: ${error.message}`);
        }
      });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  const {project_labor, project_data} = data;
  const itemsPerPage = 10; // Number of items to display per page
  const totalPages = Math.ceil(project_labor.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };
  // Get the current page's items
  const startIndex = (currentPage - 1) * itemsPerPage;
  //const endIndex = startIndex + itemsPerPage;
  //const currentItems = project_labor.slice(startIndex, endIndex);
  const maxVisiblePages = 5;
    const chart1={
        series: [{
          data: [18,67,50,18]
        }],
         options : {
          chart: {
        //   height: 265,
        //   type: 'bar',
          toolbar:{
            show: false,
          },
          events: {
            click: function(chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },        
        plotOptions: {
          bar: {
            columnWidth: '35%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          xaxis: {
              lines: {
                  show: false
              }
          },
          yaxis: {
              lines: {
                  show: true
              }
          }
        },
        legend: {
          show: false
        },
        yaxis: {
          labels: {
          offsetY:0,
          minWidth: 20,
          maxWidth: 20
          },
        },
        xaxis: {
          categories: [
            'employee',
            'labors',
            'days',
            'roles'
          ],
          labels: {
            minHeight: 22,
            maxHeight: 50,
            style: {              
              fontSize: '12px'
            }
          }
        }
        }
    }
   
    return(
        <>
           <Container fluid>
        <Row>            
            <Col lg="12">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                   <div className="d-flex align-items-center justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb p-0 mb-0">
                                <li className="breadcrumb-item"><Link to="/project">{t("project")}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{t("project_view")}</li>
                            </ol>
                        </nav>
                    </div>    
                    <Link to="/project" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">{t("back")}</span>
                    </Link>                               
                </div>
            </Col>
            <Col lg="12" className="mb-3 d-flex justify-content-between">
                <h4 className="font-weight-bold d-flex align-items-center">{t("project_view")}</h4>
            </Col>
        </Row>
       
        <Row>
            <Col lg="4">
                <Card>
                    <ListGroup as="ul" className="list-group-flush">
                        <ListGroup.Item as="li">
                            <div>
                                <ListGroup as="ul"  className="list-style-1 mb-0">
                                    <ListGroup.Item as="li" className="d-flex justify-content-start align-items-center">
                                        <div className="list-style-detail ml-4 mr-2">
                                            <h5 className="font-weight-bold">{project_data.name}</h5>
                                            <p className="mb-0 mt-1 text-muted">Project Location {project_data.location}</p>
                                        </div>                                        
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <table className="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("from_date")}</p>                                        
                                    </td>
                                    <td>
                                        <p className="mb-0 ">{formatDate(project_data.start_date)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("to_date")}</p>                                        
                                    </td>
                                    <td>
                                        <p className="mb-0 ">{formatDate(project_data.end_date)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("budget")}</p>                                        
                                    </td>
                                    <td>
                                        <p className="mb-0 ">{project_data.budget}</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" >
                            <h6 className="font-weight-bold">Project Report</h6>
                            <Chart  options={chart1.options} series={chart1.series} type="bar"  height="250px" />
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="8">
                <Card>
                    <Card.Body className="p-0">
                        <Tab.Container defaultActiveKey="first">
                        <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                            <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="first" bsPrefix="font-weight-bold px-5 py-2 " >{t("labor_list")}</Nav.Link></Nav.Item>
                            <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="second" bsPrefix="font-weight-bold text-uppercase px-5 py-2" >Activity</Nav.Link></Nav.Item>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="first" className=" fade show  ">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5>{t("labor_list")}</h5>
                                   
                                </div>
                                <div className="table-responsive">
                                    <table className="table data-table mb-0">
                                        <thead className="table-color-heading">
                                            <tr className="text-muted">
                                            <th scope="col">#</th>
                                            <th scope="col">{t("full_name")} </th>
                                            <th scope="col">{t("sex")}</th>
                                            <th scope="col">{t("phone")}</th>
                                            <th scope="col">{t("job")}</th>
                                            <th scope="col">{t("status")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                        {project_labor.map((item,rowCounter)=>(  
                                            <tr>
                                                <td>{startIndex + rowCounter + 1}</td>
                                                <td>{item.labor.first_name} {item.labor.father_name}</td>
                                                <td>
                                                {item.labor.sex}
                                                </td>
                                                <td>
                                                {item.labor.phone}
                                                </td>
                                                <td>{item.project_job.job_title}</td>
                                                <td>
                                                    <p className="mb-0 text-success d-flex justify-content-start align-items-center">
                                                        <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">                                                
                                                            <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                                                            active
                                                    </p>
                                                </td>
                                            </tr>
                                            ))}                                           
                                        </tbody>
                                        </table>
                                        <CustomPagination
                                      currentPage={currentPage}
                                    totalPages={totalPages}
                                   onPageChange={handlePageChange}
                                   maxVisiblePages={maxVisiblePages}
                     />
                                   
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second" className="  fade p-3">                                
                                <h3 className="mb-3">Activity</h3>
                                <div className="iq-timeline0 m-0 d-flex align-items-center justify-content-between position-relative">
                                <ul className="list-inline p-0 m-0">
                                        <li>
                                            <div className="pt-3">
                                                <p className="mb-0 text-muted font-weight-bold text-uppercase">{formatDate(project_data.start_date)}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                            <h6 className="float-left mb-1 font-weight-bold">Project Started</h6>                                            
                                            <div className="d-inline-block w-100">
                                                <p className="mb-0">project started now </p>
                                                <div className="d-inline-block w-100">
                                                    <p></p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                        </Tab.Container>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
        </>
    )
}
export default Projectview;