// validation.js
export const validateForm = (formData) => {
    const errors = {};
   if (!formData.employer.trim()) {
      errors.employer = "Employer is Required ";
    }
  
    if (!formData.work_place.trim()) {
      errors.work_place = "Place of Work is required";
    }
    if (!formData.position.trim()) {
        errors.position = "Work Position is required";
      }

    if (!formData.Job_description.trim()) {
        errors.Job_description = "Job Description is required";
      }  
      
      if (!formData.from_date) {
        errors.from_date = "start date is required";
      } else if (!isValidDate(formData.from_date)) {
        errors.from_date = "Invalid start date format";
      }

      if (!formData.to_date) {
        errors.to_date = "End Date is required";
      } else if (!isValidDate(formData.to_date)) {
        errors.to_date = "Invalid date format";
      } else {
        const startDate = new Date(formData.from_date);
        const graduationDate = new Date(formData.to_date);
        if (startDate > graduationDate) {
          errors.to_date = "Invalid date start date is grater than End Date";
        }
      }

    return errors;
  };
  
  
export const isValidDate = (date) => {
    // Basic date validation regex, you can enhance it
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(date);
  };