import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {useQuery} from "react-query";
import {Form, Button ,Alert,Spinner} from 'react-bootstrap';
import {compareDates,GetDatesBetween} from "../../../utils/date_utils"
import {useHistory } from 'react-router-dom';
import {get_project_data,get_project_attendance_sheet_bydate,get_project_labors} from "../../../services/projectApi";
const CreateAttendanceSheetForm = ({projectID, onSubmitButton }) => {
    const { t } = useTranslation();
    let history =useHistory();
    const [formerror,setFormError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [formData, setFormData] = useState({
      project_id:projectID,
      start_date: "",
      end_date:"",
    });
    const {data, isLoading, isError, error } = useQuery("assign_labor_project_data", async () => {
        try {
        const [projectdata] = await Promise.all([
            get_project_data()
          ]);
        return {projectdata};
        } catch (error) {
          throw new Error(`Error fetching data: ${error.message}`);
        }
      });
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Error: {error.message}</div>;
    }
    const {projectdata} = data;

    const checkSheet = async (project, fromDate, toDate) => {
      const data = await get_project_attendance_sheet_bydate(project, fromDate, toDate);
      return data;
    };
    const handleSubmit = async(e) => {
      e.preventDefault();
      const requiredFields = ['project_id', 'start_date','end_date'];
      const isEmptyField = requiredFields.some(field => !formData[field]);
      if (isEmptyField) {
        setErrorMessage("Please tray again all fields are required.");
        setFormError(true);
        return;
      }
       const laborExist=await get_project_labors(formData.project_id);
        if(laborExist.length===0){
          setErrorMessage("Please assign labor wokers first");
          setFormError(true);
          return;
        }
      if(compareDates(formData.start_date,formData.end_date)<0){
        setErrorMessage("Please tray again your date is invalid");
        setFormError(true);
        return;
      }
      if((GetDatesBetween(formData.start_date,formData.end_date).length)>15){
        setErrorMessage("Please tray again you can create maximum for 15 days");
        setFormError(true);
        return;
      }
      
      const checker=await checkSheet(formData.project_id,formData.start_date,formData.end_date);
      if(checker.length!==0){
        setErrorMessage("Please check your selection and tray again a sheet has already been created for this project");
        setFormError(true);
        return;
      }
      const Fillterproject=projectdata.filter(element => element.id ==formData.project_id);
      const project=Fillterproject[0];
      const passParameter={ project,formData}
      history.push('/create-labor-attendance-sheet',{attendanceParam:passParameter});
    };
    const handleCloseModal = () => {
      onSubmitButton();
    };
    const handleInputChange = (e, fieldName) => {
      const { value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    };
  
    return (  
       <>
      <Form onSubmit={handleSubmit}>
        {formerror?(
        <Alert variant="danger">
          {ErrorMessage}
          </Alert>
        ):""}
        <Form.Group>
          <Form.Label htmlFor="inputReason" className="font-weight-bold text-muted">
            {t("project")}
            
          </Form.Label>
          <select
          disabled={true}
            id="inputReason"
            className="form-select form-control choicesjs"
            value={formData.project_id}
            onChange={(e) => handleInputChange(e, 'project_id')}
          >
            <option value="" hidden disabled>
              Select project
            </option>
           {projectdata && projectdata.map((data) => (
          <option key={data.id} value={data.id}>
              {data.name}
            </option>
          ))}
          </select>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="inputPosition" className="font-weight-bold text-muted">
            {t("from_date")}
          </Form.Label>
          <input type='date'
            id="inputPosition"
            className="form-select form-control choicesjs"
            value={formData.start_date}
            onChange={(e) => handleInputChange(e,'start_date')}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="inputPosition" className="font-weight-bold text-muted">
            {t("to_date")}
          </Form.Label>
          <input type='date'
            id="inputPosition"
            className="form-select form-control choicesjs"
            value={formData.end_date}
            onChange={(e) => handleInputChange(e,'end_date')}
          />
        </Form.Group>
        <div className="d-flex mt-1">
          <div className="mb-1">
            <Button variant="primary" type="submit"  disabled={isSubmitting}>
                        {isSubmitting ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Sending...</span>
                          </span>
                        ) : (
                          t("create")
                        )}
            </Button>
          </div>
          <div className="col-md-6 mb-1">
            <Button variant="primary" onClick={handleCloseModal}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Form>
    </>
     );
  };
export default CreateAttendanceSheetForm;
