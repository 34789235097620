import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout1 from '../layouts/backend/Layout1';
import BlankLayout from '../layouts/BlankLayout';
import ProtectedRoute from '../middleware/protected';
import { useSelector } from 'react-redux';
const LayoutsRoute = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <Switch>
      <Route path="/auth" component={BlankLayout} />
      <Route path="/extra-pages" component={BlankLayout} />
      <ProtectedRoute
        path="/" component={Layout1}
        isAuthenticated={isAuthenticated}
      />
    </Switch>
  );
};

export default LayoutsRoute;
