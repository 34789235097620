import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { all_employee } from "../../../services/employeeApi";
import CustomPagination from "../../../utils/CustomPagination"
import {Container,Row,Col,OverlayTrigger,Tooltip} from "react-bootstrap";
import {useQuery } from "react-query";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
const Employee = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError, error } = useQuery(["employee"], () =>
    all_employee()
  );
  const [currentPage, setCurrentPage] = useState(1);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  const employee = data;
  const itemsPerPage = 10; // Number of items to display per page
  const totalPages = Math.ceil(employee.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };
  // Get the current page's items
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = employee.slice(startIndex, endIndex);
  const maxVisiblePages = 5;
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="12">
                <Card className="card-block card-stretch">
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h5 className="font-weight-bold"> {t("employe_list")}</h5>
                      <Link to="/employee-add"
                        className="btn btn-sm btn-primary position-relative d-flex align-items-center justify-content-between">
                       {t("add_employee")}
                      </Link>
                    </div>
                    <div className="table-responsive">
                      <table className="table data-table mb-0" size="sm">
                        <thead className="table-color-heading">
                          <tr className="text-light">
                            <th scope="col" className="w-01 pr-0">
                              #
                            </th>

                            <th scope="col" className="dates">
                              <label className="text-muted mb-0">
                                {t("full_name")}
                              </label>
                            </th>
                            <th scope="col">
                              <label className="text-muted mb-0">
                                {t("email")}
                              </label>
                            </th>
                            <th scope="col" className="">
                              <label className="text-muted mb-0">
                                {t("phone")}
                              </label>
                            </th>
                            <th scope="col" className="">
                              <label className="text-muted mb-0">
                                {t("sex")}
                              </label>
                            </th>
                            <th scope="col">
                              <label className="text-muted mb-0">
                                {t("status")}
                              </label>
                            </th>
                            <th scope="col" className="text-right">
                              <span className="text-muted">{t("action")}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((item, rowCounter) => (
                            <tr key={item.cust} className="white-space-no-wrap">
                                <td>{startIndex + rowCounter + 1}</td>
                              <td className="">
                                <div className="active-project-1 d-flex align-items-center mt-0 ">
                                  <div className="data-content">
                                    <div>
                                      <span className="font-weight-bold">
                                        {item.first_name} {item.father_name}
                                      </span>
                                    </div>
                                    <p className="m-0 text-secondary small">
                                      {item.phone}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>{item.email}</td>
                              <td>{item.phone}</td>
                              <td>{item.sex}</td>
                              <td>
                                <p
                                  className={`mb-0 ${item.color} font-weight-bold d-flex justify-content-start align-items-center`}
                                >
                                  <small>
                                    <svg
                                      className="mr-2"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <circle
                                        cx="12"
                                        cy="12"
                                        r="8"
                                        fill="#3cb72c"
                                      ></circle>
                                    </svg>
                                  </small>
                                  {t(item.status ? "Active" : "Not Active")}
                                </p>
                              </td>
                              <td>
                                <div className="d-flex justify-content-end align-items-center">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>View</Tooltip>}>

                                    <Link className="" to={`/employee-view/?id=${item.id}`}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="text-secondary mx-4"
                                        width="20"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                        />
                                      </svg>
                                    </Link>
                                  </OverlayTrigger>
                                 
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <CustomPagination
                     currentPage={currentPage}
                     totalPages={totalPages}
                     onPageChange={handlePageChange}
                     maxVisiblePages={maxVisiblePages}
                     />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Employee;
