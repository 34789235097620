import React from 'react';
import { Pagination } from 'react-bootstrap';

const CustomPagination = ({
  currentPage,
  totalPages,
  onPageChange,
  maxVisiblePages,
}) => {
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) {
      onPageChange(1);
    } else if (pageNumber > totalPages) {
      onPageChange(totalPages);
    } else {
      onPageChange(pageNumber);
    }
  };

  const renderPaginationItems = () => {
    const pageRange = Math.min(maxVisiblePages, totalPages);
    const pages = [];
    let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
    let endPage = Math.min(totalPages, startPage + pageRange - 1);

    if (endPage - startPage + 1 < pageRange) {
      startPage = Math.max(1, endPage - pageRange + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={currentPage === i}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return pages;
  };

  return (
    <Pagination className="justify-content-end">
      <Pagination.First onClick={() => handlePageChange(1)} />
      <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
      {renderPaginationItems()}
      <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      <Pagination.Last onClick={() => handlePageChange(totalPages)} />
    </Pagination>
  );
};

export default CustomPagination;
