import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {useQuery,useMutation,useQueryClient} from "react-query";
import { Form, Button ,Alert,Spinner} from 'react-bootstrap';
//import employeeData from "../../../store/jsondata/employeData.json";
import AlertModal from "../../sharedComponent/AlertModal";
import { useHistory } from 'react-router-dom'
import {get_empDetail_dropDown_data} from "../../../services/employeeApi";
import {RegisterProject} from "../../../services/projectApi"
const RegisterProjectForm = ({ parentID, onSubmitButton }) => {
    const { t } = useTranslation();
    let history =useHistory();
    const [formerror, setFormError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAlert, setshowAlert] = useState();
    const handleClose = () => setshowAlert(false);
    const [AlertMessage, setAlertMessage] = useState("");
    const [alertTitle, setalertTitle] = useState("");
    //const changeReasons = employeeData.employeeDetailChangeReasons;
    const queryClient = useQueryClient();
    const registrationMutation = useMutation(async (newData) => {
      await RegisterProject(newData);
      queryClient.invalidateQueries('project');
    });
    const [formData, setFormData] = useState({
    name:"",
     location:"",
    budget:"",
     start_date: "",
     end_date:"",
     branch_id:1,
     status: 1,
     duration:"",
     created_by:"",
     updated_by: "",
    });
  
    const { data, isLoading, isError, error } = useQuery(["emp_detail"], async () => {
      try {
        const dropDownData = await get_empDetail_dropDown_data();
        return { dropDownData };
      } catch (error) {
        throw new Error(`Error fetching data: ${error.message}`);
      }
    });
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Error: {error.message}</div>;
    }
    const { dropDownData } = data;
    const handleRegistration = async (employeeData) => {
      try {
        setIsSubmitting(true);
        await registrationMutation.mutateAsync(employeeData);
        setalertTitle("Success");
        setAlertMessage("Registration successful");
        setTimeout(() => {
          history.push(`/project`);
        },2000);
        
      } catch (error) {
        setalertTitle("Error");
        setAlertMessage(error.message);
      } finally {
        setIsSubmitting(false);
        setshowAlert(true);
      }
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      const requiredFields = ['name','duration','location', 'budget', 'start_date', 'end_date', 'branch_id'];
      const isEmptyField = requiredFields.some(field => !formData[field]);
      if (isEmptyField) {
        setFormError(true);
        return;
      }
      handleRegistration(formData);
    };
  
    const handleCloseModal = () => {
      onSubmitButton();
    };
  
    const handleInputChange = (e, fieldName) => {
      const { value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    };
  
    return (  
       <>
      <Form onSubmit={handleSubmit}>
        {formerror?(
        <Alert variant="danger"><p>Please tray again all fields are required.</p> </Alert>
        ):""}
       
        <div className="col-md-12 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("project_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter project name"
                          value={formData.name}
                          onChange={(e) => handleInputChange(e, "name")}
                        />
                      </div>

        <div className="col-md-12 mb-3">
                      <Form.Label htmlFor="inputPosition" className="font-weight-bold text-muted">
                        {t("branch")}
                      </Form.Label>
                      <select
                        id="inputPosition"
                        className="form-select form-control choicesjs"
                        value={formData.branch_id}
                        onChange={(e) => handleInputChange(e, 'branch_id')}
                      >
                        <option value="" hidden disabled>
                          Select Branch
                        </option>
                        {dropDownData.Branch.map((data) => (
                          <option key={data.id} value={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                      </div>
                      <div className="col-md-12 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("project_location")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter project locaation "
                          value={formData.location}
                          onChange={(e) => handleInputChange(e, "location")}
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("project_estimated_duration")}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter project estimated duration "
                          value={formData.duration}
                          onChange={(e) => handleInputChange(e, "duration")}
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("estimated_budget")}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter project estimation budget "
                          value={formData.budget}
                          onChange={(e) => handleInputChange(e, "budget")}
                        />
                      </div>

                      <div className="col-md-12 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("from_date")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter project start date"
                          value={formData.start_date}
                          onChange={(e) => handleInputChange(e, "start_date")}
                        />
                       
                      </div>
                      <div className="col-md-12 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("to_date")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter end date"
                          value={formData.end_date}
                          onChange={(e) => handleInputChange(e, "end_date")}
                        />
                       
                      </div>
                     


        <div className="d-flex mt-1">
          <div className="mb-1">
            <Button variant="primary" type="submit"  disabled={isSubmitting}>
                      
                        {isSubmitting ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Sending...</span>
                          </span>
                        ) : (
                          t("register")
                        )}
</Button>
          </div>
          <div className="col-md-6 mb-1">
            <Button variant="primary" onClick={handleCloseModal}>
              {t("cancel")}
            </Button>
          </div>
        </div>


      </Form>
   
  
      <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
    </>
     );
  };
  

export default RegisterProjectForm;
