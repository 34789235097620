import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {useQuery,useMutation,useQueryClient } from "react-query";
import { Form, Button ,Alert,Spinner} from 'react-bootstrap';
import employeeData from "../../../store/jsondata/employeData.json";
import AlertModal from "../../sharedComponent/AlertModal";
import {get_empDetail_dropDown_data,RegisterrEmpDetail} from "../../../services/employeeApi";
const EmplpoyeeDetailRegistrationForm = ({ parentID, onSubmitButton }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const registrationMutation = useMutation(async (newData) => {
      await RegisterrEmpDetail(newData);
      queryClient.invalidateQueries('employeeview');
    });
    const [formerror, setFormError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAlert, setshowAlert] = useState();
    const handleClose = () => setshowAlert(false);
    const [AlertMessage, setAlertMessage] = useState("");
    const [alertTitle, setalertTitle] = useState("");
    const changeReasons = employeeData.employeeDetailChangeReasons;
    const [formData, setFormData] = useState({
      emp_id: parentID,
      branch_id: "",
      grade_id: "",
      step_id: "",
      position_id: "",
      update_reason: "",
      status: 1,
      updated_by: "melkamu",
    });
  
    const { data, isLoading, isError, error } = useQuery(["employeeRegistration"], async () => {
      try {
        const dropDownData = await get_empDetail_dropDown_data();
        return { dropDownData };
      } catch (error) {
        throw new Error(`Error fetching data: ${error.message}`);
      }
    });
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (isError) {
      return <div>Error: {error.message}</div>;
    }
    const { dropDownData } = data;
    const handleRegistration = async (employeeData) => {
      try {
        setIsSubmitting(true);
        await registrationMutation.mutateAsync(employeeData);
        setalertTitle("Success");
        setAlertMessage("Registration successful");
        handleCloseModal();
      } catch (error) {
        setalertTitle("Error");
        setAlertMessage(error.message);
      } finally {
        setIsSubmitting(false);
        setshowAlert(true);
      }
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      const requiredFields = ['emp_id','update_reason', 'position_id', 'grade_id', 'step_id', 'branch_id'];
      const isEmptyField = requiredFields.some(field => !formData[field]);
      if (isEmptyField) {
        setFormError(true);
        return;
      }
      handleRegistration(formData);
    };
  
    const handleCloseModal = () => {
      onSubmitButton();
    };
  
    const handleInputChange = (e, fieldName) => {
      const { value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    };
  
    return (  
       <>
      <Form onSubmit={handleSubmit}>
        {formerror?(
        <Alert variant="danger"><p>Please tray again all fields are required.</p> </Alert>
        ):""}
        <Form.Group>
          <Form.Label htmlFor="inputReason" className="font-weight-bold text-muted">
            {t("add_detail_reason")}
          </Form.Label>
          <select
            id="inputReason"
            className="form-select form-control choicesjs"
            value={formData.update_reason}
            onChange={(e) => handleInputChange(e, 'update_reason')}
          >
            <option value="" hidden disabled>
              Select Add Reason
            </option>
            {changeReasons.map((data) => (
              <option key={data.id} value={data.name}>
                {t(data.name)}
              </option>
            ))}
          </select>
        </Form.Group>
  
        <Form.Group>
          <Form.Label htmlFor="inputPosition" className="font-weight-bold text-muted">
            {t("position")}
          </Form.Label>
          <select
            id="inputPosition"
            className="form-select form-control choicesjs"
            value={formData.position_id}
            onChange={(e) => handleInputChange(e, 'position_id')}
          >
            <option value="" hidden disabled>
              Select Position
            </option>
            {dropDownData.Position.map((data) => (
              <option key={data.id} value={data.id}>
                {data.name}
              </option>
            ))}
          </select>
        </Form.Group>
  
        <Form.Group>
          <Form.Label htmlFor="inputPosition" className="font-weight-bold text-muted">
            {t("grade")}
          </Form.Label>
          <select
            id="inputPosition"
            className="form-select form-control choicesjs"
            value={formData.grade_id}
            onChange={(e) => handleInputChange(e, 'grade_id')}
          >
            <option value="" hidden disabled>
              Select Position
            </option>
            {dropDownData.Grade.map((data) => (
              <option key={data.id} value={data.id}>
                {data.name}
              </option>
            ))}
          </select>
        </Form.Group>
        
        <Form.Group>
          <Form.Label htmlFor="inputPosition" className="font-weight-bold text-muted">
            {t("step")}
          </Form.Label>
          <select
            id="inputPosition"
            className="form-select form-control choicesjs"
            value={formData.step_id}
            onChange={(e) => handleInputChange(e, 'step_id')}
          >
            <option value="" hidden disabled>
              Select Position
            </option>
            {dropDownData.Step.map((data) => (
              <option key={data.id} value={data.id}>
                {data.name}
              </option>
            ))}
          </select>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="inputPosition" className="font-weight-bold text-muted">
            {t("branch")}
          </Form.Label>
          <select
            id="inputPosition"
            className="form-select form-control choicesjs"
            value={formData.branch_id}
            onChange={(e) => handleInputChange(e, 'branch_id')}
          >
            <option value="" hidden disabled>
              Select Branch
            </option>
            {dropDownData.Branch.map((data) => (
              <option key={data.id} value={data.id}>
                {data.name}
              </option>
            ))}
          </select>
        </Form.Group>
        <div className="d-flex mt-1">
          <div className="mb-1">
            <Button variant="primary" type="submit"  disabled={isSubmitting}>
                      
                        {isSubmitting ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Sending...</span>
                          </span>
                        ) : (
                          t("register")
                        )}
</Button>
          </div>
          <div className="col-md-6 mb-1">
            <Button variant="primary" onClick={handleCloseModal}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Form>
   
  
      <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
    </>
     );
  };
  

export default EmplpoyeeDetailRegistrationForm;
