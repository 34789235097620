const lang = {
    edit: "ጻፍ",
    save: "መዝግብ",
    cancel: "አቋርጥ",
    delete: "አጥፋ",
    create: "መዝግብ",
    update: "ቀይር",
    search: "ፈልግ",
    select: "ምረጥ",
    view: "አስስ",
    submit: "ላክ",
    add: "መዝግብ",
    remove: "አጥፋ",
    close: "አቋርጥ",
    next: "Next",
    previous: "Previous",
    filter: "Filter",
    sort: "Sort",
    clear: "Clear",
    manage: "Manage",
    import: "Import",
    export: "Export",
    download: "Download",
    upload: "Upload",
    print: "Print",
    send: "Send",
    receive: "Receive",
    approve: "Approve",
    reject: "Reject",
    complete: "Complete",
    confirm: "Confirm",
    error: "Error",
    information: "Information",
    status: "Status",
    settings: "Settings",
    preferences: "Preferences",
    notification: "Notification",
    navigation: "Navigation",
    home: "Home",
    dashboard: "Dashboard",
    profile: "Profile",
    user: "User",
    account: "Account",
    supplier: "Supplier",
    product: "ምርት",
    order: "ትእዛዝ",
    payment: "Payment",
    report: "Report",
    analytics: "Analytics",
    audit: "Audit",
    email: "Email",
    phone: "Phone",
    address: "Address",
    city: "City",
    state: "State",
    country: "Country",
    task: "Task",
    schedule: "Schedule",
    calendar: "Calendar",
    logout: "Logout",
    overview: "Overview",
    details: "Details",
    notes: "Notes",
    notifications:"Notifications",
    help: "Help",
    about: "About",
    language: "Language",
    timezone: "Timezone",
    date: "Date",
    time: "Time",
    quantity: "Quantity",
    price: "Price",
    amount: "Amount",
    discount: "Discount",
    subtotal: "Subtotal",
    total: "Total",
    shipped: "Shipped",
    delivered: "Delivered",
    pending: "Pending",
    cancelled: "Cancelled",
    returned: "Returned",
    exchange: "Exchange",
    refund: "Refund",
    login: "Login",
    register: "Register",
    sale: "Sale",
    tax: "Tax",
    item: "Item",
    description: "Description",
    completed: "Completed",
    overdue: "Overdue",
    today: "Today",
    tomorrow: "Tomorrow",
    recurring: "Recurring",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
    day: "Day",
    week: "Week",
    month: "Month",
    year: "Year",
    subject: "Subject",
    message: "Message",
    sent: "Sent",
    drafts: "Drafts",
    enable: "Enable",
    disable: "Disable",
    on: "On",
    off: "Off",
    loading: "Loading",
    info: "Info!",
    confirmation: "Confirmation",
    yes: "Yes",
    no: "No",
    hello: "Hello!",
    name: "Name",
    invoice: "ደረሰኝ",
    quote: "Quote",
    offer: "Offer",
    lead: "Lead",
    customer: "ደንበኛ",
    admin: "Admin",
    employee: "Employee",
    currency: "Currency",
    invoice_list: "Invoice List",
    add_new_invoice: "Add New Invoice",
    record_payment: "Record Payment",
    quote_list: "Quote List",
    add_new_quote: "Add New Quote",
    offer_list: "Offer List",
    add_new_offer: "Add New Offer",
    lead_list: "Lead List",
    add_new_lead: "Add New Lead",
    customer_list: "Customer List",
    add_new_customer: "Add New Customer",
    payment_list: "Payment List",
    add_new_payment: "Add New Payment",
    settings_list: "Settings List",
    add_new_settings: "Add New Settings",
    admin_list: "Admin List",
    add_new_admin: "Add New Admin",
    employee_list: "Employee List",
    add_new_employee: "Add New Employee",
    currency_list: "Currency List",
    add_new_currency: "Add New Currency",
    payment_mode: "Payment Mode",
    payment_mode_list: "Payment Mode List",
    add_new_payment_mode: "Add New Payment Mode",
    email_template: "Email Template",
    email_template_list: "Email Template List",
    add_new_email_template: "Add New Email Template",
    product_list: "Product List",
    add_new_product: "Add New Product",
    order_list: "Order List",
    add_new_order: "Add New Order",
    general_settings: "General Settings",
    email_templates: "Email Templates",
    birthday: "Birthday",
    birthplace: "Birthplace",
    department: "Department",
    gender: "Gender",
    position: "Position",
    accepted: "Accepted",
    add_field: "Add Field",
    advanced_settings: "Advanced Settings",
    after: "After",
    allowed_role: "Allowed Role",
    app_name: "App Name",
    app_settings: "App Settings",
    available_variables: "Available Variables",
    back: "Back",
    balance: "Balance",
    before: "Before",
    body: "Body",
    cent_precision: "Cent Precision",
    client: "Client",
    company: "Company",
    company_settings: "Company Settings",
    convert_to_invoice: "Convert To Invoice",
    crm_settings: "Crm Settings",
    currency_format: "Currency Format",
    currency_name: "Currency Name",
    currency_position: "Currency Position",
    currency_symbol: "Currency Symbol",
    decimal_separator: "Decimal Separator",
    default_mode: "Default Mode",
    delete_confirmation: "Delete Confirmation",
    download_pdf: "Download Pdf",
    draft: "Draft",
    email_content: "Email Content",
    enabled: "Enabled",
    expire_date: "Expire Date",
    finance_settings: "Finance Settings",
    first_name: "First Name",
    interested: "Interested",
    last_name: "Last Name",
    manager_first_name: "Manager First Name",
    manager_last_name: "Manager Last Name",
    money_format_settings: "Money Format Settings",
    new: "New",
    not_interested: "Not Interested",
    note: "Note",
    number: "Number",
    password: "Password",
    payment_information: "Payment Information",
    photo: "Photo",
    position_in_company: "Position In Company",
    preview: "Preview",
    profile_settings: "Profile Settings",
    reached: "Reached",
    recent_invoices: "Recent Invoices",
    recent_quotes: "Recent Quotes",
    reference: "Reference",
    refresh: "Refresh",
    role: "Role",
    select_language: "Select Language",
    send_by_email: "Send By Email",
    setting: "Setting",
    setting_category: "Setting Category",
    setting_name: "Setting Name",
    show: "Show",
    show_invoice: "Show Invoice",
    sorry_the_page_you_requested_does_not_exist: "Sorry The Page You Requested Does Not Exist",
    source: "Source",
    tax_total: "Tax Total",
    thousand_separator: "Thousand Separator",
    to_write_a_variable_name_use_the_convention: "To Write A Variable Name Use The Convention",
    total_paid: "Total Paid",
    total_remaining: "Total Remaining",
    type: "Type",
    update_currency_format: "Update Currency Format",
    update_password: "Update Password",
    update_your_company_informations: "Update Your Company Informations",
    zero_format: "Zero Format",
    no_results_found: "No Results Found",
    please_wait: "Please Wait",
    are_you_sure: "Are you sure?",
    confirm_password: "Confirm Password",
    current_password: "Current Password",
    new_password: "New Password",
    remember_me: "Remember Me",
    thank_you_exclamation: "Thank you!",
    welcome_back: "Welcome Back!",
    payment_status: "Payment Status",
    error_404: "Error 404 - Page Not Found",
    error_500: "Error 500 - Internal Server Error",
    sub_total: "Sub Total",
    are_you_sure_you_want_to_delete: "Are you sure you want to delete : ",
    core_setting: "Core Setting",
    created: "Created",
    created_by: "Created By",
    credit: "Credit",
    currency_settings: "Currency Settings",
    declined: "Declined",
    default: "Default",
    due_balance: "Due Balance",
    expired: "Expired",
    expired_date: "Expired Date",
    invoices_preview: "Invoices Preview",
    not_paid: "Not Paid",
    offers_preview: "Offers Preview",
    paid: "Paid",
    partially: "Partially",
    payments_preview: "Payments Preview",
    quotes_preview: "Quotes Preview",
    template: "Template",
    this_month: "This Month",
    unpaid: "Unpaid",
    value: "Value",
    active_customer: "Active Customer",
    customer_preview: "Customer Preview",
    new_customer_this_month: "New Customer This Month",
    already_have_account_login: "Already Have Account Login",
    forgot_password: "Forgot Password",
    log_in: "Log In",
    or: "Or",
    sign_in: "Sign In",
    sign_up: "Sign Up",
    register_now: "Register Now",
    forget_password: "Forget Password",
    request_new_password: "Request New Password",
    manage_your_company_with: "Manage Your Company With",
    all_in_one_tool: "All In One Tool",
    easily_add_and_manage_your_services: "Easily Add And Manage Your Services",
    it_brings_together_your_invoice_clients_and_leads: "It Brings Together Your Invoice Clients And Leads",
    run_and_scale_your_erp_crm_apps: "Run And Scale Your Erp Crm Apps",
    people: "People",
    customize_this_application: "Customize This Application",
    do_you_need_help_on_customize_of_this_app: "Do You Need Help On Customize Of This App",
    contact_us: "Contact Us",
    company_logo: "Company Logo",
    application_name: "Application Name",
    allow_registration: "Allow Registration",
    application_email: "Application Email",
    application_url: "Application Url",
    mutli_branch: "Mutli Branch",
    company_name: "Company Name",
    company_address: "Company Address",
    company_state: "Company State",
    company_country: "Company Country",
    company_email: "Company Email",
    company_phone: "Company Phone",
    company_cell: "Company Cell",
    company_website: "Company Website",
    company_tax_number: "Company Tax Number",
    company_vat_number: "Company Vat Number",
    company_reg_number: "Company Reg Number",
    update_company_logo: "Update Company Logo",
    update_company_finance_settings: "Update Company Finance Settings",
    last_invoice_number: "Last Invoice Number",
    last_quote_number: "Last Quote Number",
    last_offer_number: "Last Offer Number",
    last_payment_number: "Last Payment Number",
    invoice_prefix: "Invoice Prefix",
    quote_prefix: "Quote Prefix",
    offer_prefix: "Offer Prefix",
    payment_prefix: "Payment Prefix",
    current_invoice_year: "Current Invoice Year",
    current_quote_year: "Current Quote Year",
    current_offer_year: "Current Offer Year",
    algeria: "Algeria",
    company_list: "Company List",
    add_new_company: "Add New Company",
    contact: "Contact",
    website: "Website",
    france: "France",
    afghanistan: "Afghanistan",
    albania: "Albania",
    andorra: "Andorra",
    angola: "Angola",
    anguilla: "Anguilla",
    argentina: "Argentina",
    armenia: "Armenia",
    aruba: "Aruba",
    australia: "Australia",
    austria: "Austria",
    azerbaijan: "Azerbaijan",
    bahamas: "Bahamas",
    bahrain: "Bahrain",
    bangladesh: "Bangladesh",
    barbados: "Barbados",
    belarus: "Belarus",
    belgium: "Belgium",
    belize: "Belize",
    benin: "Benin",
    bermuda: "Bermuda",
    bhutan: "Bhutan",
    bolivia: "Bolivia",
    bosnia_and_herzegovina: "Bosnia And Herzegovina",
    botswana: "Botswana",
    brazil: "Brazil",
    brunei_darussalam: "Brunei Darussalam",
    bulgaria: "Bulgaria",
    burkina_faso: "Burkina Faso",
    burundi: "Burundi",
    cabo_verde: "Cabo Verde",
    cambodia: "Cambodia",
    cameroon: "Cameroon",
    canada: "Canada",
    central_african_republic: "Central African Republic",
    chad: "Chad",
    chile: "Chile",
    china: "China",
    colombia: "Colombia",
    comoros: "Comoros",
    congo: "Congo",
    costa_rica: "Costa Rica",
    croatia: "Croatia",
    cuba: "Cuba",
    cyprus: "Cyprus",
    czechia: "Czechia",
    cote_d_ivoire: "Cote D Ivoire",
    denmark: "Denmark",
    djibouti: "Djibouti",
    dominica: "Dominica",
    dominican_republic: "Dominican Republic",
    ecuador: "Ecuador",
    egypt: "Egypt",
    el_salvador: "El Salvador",
    eritrea: "Eritrea",
    estonia: "Estonia",
    eswatini: "Eswatini",
    ethiopia: "Ethiopia",
    fiji: "Fiji",
    finland: "Finland",
    gabon: "Gabon",
    gambia: "Gambia",
    georgia: "Georgia",
    germany: "Germany",
    ghana: "Ghana",
    gibraltar: "Gibraltar",
    greece: "Greece",
    greenland: "Greenland",
    guadeloupe: "Guadeloupe",
    guam: "Guam",
    guatemala: "Guatemala",
    guernsey: "Guernsey",
    guinea: "Guinea",
    guinea_bissau: "Guinea Bissau",
    guyana: "Guyana",
    haiti: "Haiti",
    honduras: "Honduras",
    hong_kong: "Hong Kong",
    hungary: "Hungary",
    iceland: "Iceland",
    india: "India",
    indonesia: "Indonesia",
    iran: "Iran",
    iraq: "Iraq",
    ireland: "Ireland",
    israel: "Israel",
    italy: "Italy",
    jamaica: "Jamaica",
    japan: "Japan",
    jordan: "Jordan",
    kazakhstan: "Kazakhstan",
    kenya: "Kenya",
    korea: "Korea",
    kuwait: "Kuwait",
    kyrgyzstan: "Kyrgyzstan",
    latvia: "Latvia",
    lebanon: "Lebanon",
    lesotho: "Lesotho",
    liberia: "Liberia",
    libya: "Libya",
    liechtenstein: "Liechtenstein",
    lithuania: "Lithuania",
    luxembourg: "Luxembourg",
    madagascar: "Madagascar",
    malawi: "Malawi",
    malaysia: "Malaysia",
    maldives: "Maldives",
    mali: "Mali",
    malta: "Malta",
    martinique: "Martinique",
    mauritania: "Mauritania",
    mauritius: "Mauritius",
    mexico: "Mexico",
    moldova: "Moldova",
    monaco: "Monaco",
    mongolia: "Mongolia",
    montenegro: "Montenegro",
    montserrat: "Montserrat",
    morocco: "Morocco",
    mozambique: "Mozambique",
    myanmar: "Myanmar",
    namibia: "Namibia",
    nepal: "Nepal",
    netherlands: "Netherlands",
    new_caledonia: "New Caledonia",
    new_zealand: "New Zealand",
    nicaragua: "Nicaragua",
    niger: "Niger",
    nigeria: "Nigeria",
    norway: "Norway",
    oman: "Oman",
    pakistan: "Pakistan",
    palestine: "Palestine",
    panama: "Panama",
    papua_new_guinea: "Papua New Guinea",
    paraguay: "Paraguay",
    peru: "Peru",
    philippines: "Philippines",
    poland: "Poland",
    portugal: "Portugal",
    puerto_rico: "Puerto Rico",
    qatar: "Qatar",
    macedonia: "Macedonia",
    romania: "Romania",
    russia: "Russia",
    rwanda: "Rwanda",
    r_union: "R Union",
    saudi_arabia: "Saudi Arabia",
    senegal: "Senegal",
    serbia: "Serbia",
    singapore: "Singapore",
    slovakia: "Slovakia",
    slovenia: "Slovenia",
    somalia: "Somalia",
    south_africa: "South Africa",
    south_sudan: "South Sudan",
    spain: "Spain",
    sri_lanka: "Sri Lanka",
    sudan: "Sudan",
    suriname: "Suriname",
    sweden: "Sweden",
    switzerland: "Switzerland",
    syria: "Syria",
    taiwan: "Taiwan",
    tajikistan: "Tajikistan",
    tanzania: "Tanzania",
    thailand: "Thailand",
    timor_leste: "Timor Leste",
    togo: "Togo",
    tonga: "Tonga",
    tunisia: "Tunisia",
    turkey: "Turkey",
    turkmenistan: "Turkmenistan",
    uganda: "Uganda",
    ukraine: "Ukraine",
    united_arab_emirates: "United Arab Emirates",
    united_kingdom: "United Kingdom",
    united_states: "United States",
    uruguay: "Uruguay",
    uzbekistan: "Uzbekistan",
    venezuela: "Venezuela",
    vietnam: "Vietnam",
    yemen: "Yemen",
    zambia: "Zambia",
    zimbabwe: "Zimbabwe",
    person: "Person",
    people_list: "People List",
    add_new_person: "Add New Person",
    firstname: "Firstname",
    lastname: "Lastname",
    client_list: "Client List",
    add_new_client: "Add New Client",
    app_title: "የድርጅት ሀብት ዕቅድ (ERP) ስርዓት",
    verify_your_account: "Verify Your Account",
    complete_verification_by_providing_the_code_that_you_received_by_email: "Complete Verification By Providing The Code That You Received By Email",
    verify_now: "Verify Now",
    expense: "Expense",
    product_category: "Product Category",
    expenses_category: "Expenses Category",
    taxes: "Tax",
    product_category_list: "Product Category List",
    add_new_product_category: "Add New Product Category",
    color: "Color",
    select_date: "Select Date",
    expense_list: "Expense List",
    add_new_expense: "Add New Expense",
    expense_category: "Expense Category",
    ref: "Reference",
    expense_category_list: "Expense Category List",
    add_new_expense_category: "Add New Expense Category",
    taxes_list: "Taxes List",
    add_new_tax: "Add New Tax",
    date_format: "Date Format",
    server_url: "Server Url",
    }
     export default lang