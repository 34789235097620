// validation.js
export const validateForm = (formData) => {
    const errors = {};
   if (!formData.major_field.trim()) {
      errors.major_field = "Field of Study is required";
    }
  
    if (!formData.educational_institution.trim()) {
      errors.educational_institution = "Educational Institution is required";
    }
    if (!formData.education_level.trim()) {
        errors.education_level = "Education Level is required";
      }

    if (!formData.academic_result.trim()) {
        errors.academic_result = "Academic result is required";
      }  
      
      if (!formData.from_date) {
        errors.from_date = "start date is required";
      } else if (!isValidDate(formData.from_date)) {
        errors.from_date = "Invalid start date format";
      }

      if (!formData.graduation_year) {
        errors.graduation_year = "Graduation Date is required";
      } else if (!isValidDate(formData.graduation_year)) {
        errors.graduation_year = "Invalid date format";
      } else {
        const startDate = new Date(formData.from_date);
        const graduationDate = new Date(formData.graduation_year);
        if (startDate > graduationDate) {
          errors.graduation_year = "Invalid date start date is grater than graduation year";
        }
      }

    if (!formData.institution_location.trim()) {
        errors.institution_location = "institution location is required";
      }

    return errors;
  };
  
  
export const isValidDate = (date) => {
    // Basic date validation regex, you can enhance it
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(date);
  };