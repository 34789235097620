import React, {useState,useEffect} from 'react';
import {useTranslation } from "react-i18next";
import {useQuery,useMutation,useQueryClient } from "react-query";
import {useLocation } from 'react-router-dom';
import {Form, Button,Table,Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {formatDate} from '../../../utils/date_utils';
import {get_project_data} from "../../../services/projectApi";
import {get_labor_attendance,UpdateBulkAtendance} from "../../../services/projectApi";
import AlertModal from "../../sharedComponent/AlertModal";
import { toast } from 'react-toastify';
const AttendanceRecordForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const registrationMutation = useMutation(async (newData) => {
    await UpdateBulkAtendance(newData);
    queryClient.invalidateQueries('labor_attendance_sheet');
  });
  const dataRecived = location.state.data;
  const attendanceType = dataRecived.attendaceType;
  const [selectedProject] = useState(dataRecived.project);
  const [selectedDate] = useState(dataRecived.date);
  const [attendanceTypeName, setattendanceTypeName] = useState();
  const [attendanceTypeStatus,setattendanceTypeStatus] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const {data, isLoading, isError, error} = useQuery(
    ['labor_attendance', selectedProject, selectedDate],
    () => {
      const fetchData = async () => {
        const [LaborList, getProject] = await Promise.all([
          get_labor_attendance(selectedProject, selectedDate),
          get_project_data(),
        ]);
        return { LaborList, getProject };
      };
      return fetchData();
    }
  );
  const [attendanceState, setAttendanceState] = useState({});
  const [absentState, setAbsentState] = useState({});
  const [presentState, setPresentState] = useState({});
  const setAttendanceTypeData=()=>{
    const currentType= Number(attendanceType);
    if(currentType===0){
      setattendanceTypeName("morning");
      setattendanceTypeStatus("morning_record");
    }
    if(currentType===1){
      setattendanceTypeName("afternoon");
      setattendanceTypeStatus("afternoon_record");
    }
    if(currentType===2){
      setattendanceTypeName("over_time");
      setattendanceTypeStatus("over_time_record");
    }
    if(currentType===3){
      setattendanceTypeName("holiday");
      setattendanceTypeStatus("holiday_record");
    }
    if(currentType===4){
      setattendanceTypeName("morning");
      setattendanceTypeStatus("morning_record");
       }
    }
  useEffect(() => {
    setAttendanceTypeData();
  }, [dataRecived]);
  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  const { LaborList} = data || {};
  if (!LaborList) {
    return <div>Loading...</div>;
  }

  const prepareFormData = (attendanceType,attendanceStatus) => {
    return LaborList.map((employee) => {
      const type = attendanceState[employee.id] || 0;
      const formData = { id: employee.id };
      if (attendanceType) {
        formData[attendanceType] = type;
        formData[attendanceStatus] = true;
      } else {
        formData.attendanceType = type;
        formData.attendanceStatus = true;
      }
      return formData;
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAttendanceTypeData();
    try {
      setIsSubmitting(true);
      const submittedData = prepareFormData(attendanceTypeName,attendanceTypeStatus);
      await registrationMutation.mutate(submittedData);
      toast.success('Attendance registered successfully', {
        position: 'top-right',
        autoClose: 4000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      history.push(`/labor-attendance-sheet`);  
    } catch (error) {
      setalertTitle("Error");
      setAlertMessage(error.message);
      setshowAlert(true);
    } finally {
    setIsSubmitting(false);
    }
  };

  const CancelRecordAttendance = () => {
    history.push(`/labor-attendance-sheet`);
  };

  const handleAttendanceChange = (employeeId, value) => {
    const isAbsent = absentState[employeeId];
    const attendanceValue = isAbsent ? 0 : parseFloat(value) || undefined;
    setAttendanceState((prevAttendance) => ({
      ...prevAttendance,
      [employeeId]: attendanceValue,
    }));
  };

  const handlePresentChange = (employeeId, checked) => {
    if (checked) {
      setAttendanceState((prevAttendance) => ({
        ...prevAttendance,
        [employeeId]: 4,
      }));
      setAbsentState((prevAbsent) => ({
        ...prevAbsent,
        [employeeId]: false,
      }));
      setPresentState((prevPresent) => ({
        ...prevPresent,
        [employeeId]: true,
      }));
    } else {
      setAttendanceState((prevAttendance) => ({
        ...prevAttendance,
        [employeeId]: undefined,
      }));
      setPresentState((prevPresent) => ({
        ...prevPresent,
        [employeeId]: false,
      }));
    }
  };

  const handleAbsentChange = (employeeId, checked) => {
    if (checked) {
      setAttendanceState((prevAttendance) => ({
        ...prevAttendance,
        [employeeId]: 0,
      }));
      setAbsentState((prevAbsent) => ({
        ...prevAbsent,
        [employeeId]: true,
      }));
      setPresentState((prevPresent) => ({
        ...prevPresent,
        [employeeId]: false,
      }));
    } else {
      setAttendanceState((prevAttendance) => ({
        ...prevAttendance,
        [employeeId]: undefined,
      }));
      setAbsentState((prevAbsent) => ({
        ...prevAbsent,
        [employeeId]: false,
      }));
    }
  };
  return (
    <>
     <div className="d-flex justify-content-between align-items-center p-3">
      <h6 className="font-weight-bold">{formatDate(selectedDate)+" "+dataRecived.projectName+" Project " +dataRecived.attendanceName+ " labor attendance"}</h6>
      <div className="d-flex flex-wrap align-items-center justify-content-between">
       
      </div>
    </div>
      <Form onSubmit={handleSubmit}>
      <Table striped="columns" responsive size='sm'>
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Time</th>
              <th>Present</th>
              <th>Absent</th>
            </tr>
          </thead>
          <tbody>
        {LaborList.map((employee, index) => (
          <tr key={employee.id} className="table-active">
            <td>
            {index + 1}
            </td>
            <td>
             {employee.labor.first_name} {employee.labor.father_name}
            </td>
            <td>
            <input
              type="number" required
              step="0.1"
              className="form-control-sm"
              max={4}
              min={0}
              value={absentState[employee.id] ? 0 : attendanceState[employee.id] || ""}
              onChange={(e) => handleAttendanceChange(employee.id, e.target.value)}
              disabled={absentState[employee.id]}
            />
            </td>
            <td>
              <input 
                type="checkbox"
                onChange={(e) => handlePresentChange(employee.id, e.target.checked)}
                checked={presentState[employee.id]}
                className="form-control-lg"
              />
            </td>
            <td>
              <input
                type="checkbox"
                onChange={(e) => handleAbsentChange(employee.id, e.target.checked)}
                checked={absentState[employee.id]}
                className="form-select form-control-lg"
              />
            </td>
          </tr>
        ))}
          </tbody>
        </Table>
        <div className="d-flex mt-1">
          <div className="mb-1">
            <Button variant="info" type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <span>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Sending...</span>
                </span>
              ) : (
                t("register")
              )}
            </Button>
          </div>
          <div className="col-md-6 mb-1">
            <Button variant="primary" onClick={CancelRecordAttendance}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Form>
      <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
    </>
  );
};
export default AttendanceRecordForm;
