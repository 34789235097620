//router
import LayoutsRoute from './router/layouts-route';

//scss files
import './assets/scss/backend.scss';
import  './assets/css/custom.css';
import React, { useEffect } from 'react';
function App() {
  useEffect(() => {
    const appInfoIcon = document.querySelector('[data-app-info-icon]');
    const extensionsIcon = document.querySelector('[data-extensions-icon]');

    if (appInfoIcon) {
      appInfoIcon.addEventListener('click', (event) => {
        event.preventDefault();
        // Optionally, provide custom feedback here
      });
    }

    if (extensionsIcon) {
      extensionsIcon.addEventListener('click', (event) => {
        event.preventDefault();
        // Optionally, provide custom feedback here
      });
    }
  }, []);


  return (
    <div className="App">
      <LayoutsRoute />
    </div>
  );
}

export default App;
