export const AttendanceTime = (eventKey) => {
    let selectedTime;
    switch (eventKey) {
     
      case '0':
        selectedTime = 'Morning';
        break;
      case '1':
        selectedTime = 'After noon';
        break;
      case '2':
        selectedTime = 'Over Time';
        break;
      case '3':
        selectedTime = 'Holiday';
        break;
        case '4':
          selectedTime = 'Full Day';
          break;
      default:
        selectedTime = 'Invalid Time';
        break;
    }
    return selectedTime;
  };


  const isDatesAscending = (dates) => {
    for (let i = 1; i < dates.length; i++) {
        if (new Date(dates[i - 1]) > new Date(dates[i])) {
            return false;
        }
    }
    return true;
};

// Restructuring the data
export const restructuredAttendanceData =(data)=>{
const newData=data.map(item => {
    const { attendance_dates } = item;
    if (!isDatesAscending(attendance_dates)) {
      attendance_dates.sort((a, b) => new Date(a) - new Date(b));
  }
  const number_of_days = attendance_dates.length;
  const from_date = attendance_dates[0];
  const to_date = attendance_dates[attendance_dates.length - 1];
    return {
        ...item,
        number_of_days,
        from_date,
        to_date,
    };
})
return newData; 
}