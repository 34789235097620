import React, { useState, useEffect } from "react";
import {useTranslation } from "react-i18next";
import {Container, Row, Col, Button,Spinner,Modal} from "react-bootstrap";
import Card from "../../../components/Card";
import {useHistory,useLocation } from "react-router-dom";
import {formatDate } from "../../../utils/date_utils";
import {useQuery,useMutation } from "react-query";
import AlertModal from "../../sharedComponent/AlertModal";
import {get_labor_attendance,get_project_labors,CreateBulkAtendanceSheet} from "../../../services/projectApi"
import {toast} from 'react-toastify';
  const AddLabortoProject = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);
  const [show, setShow] = useState(false);
  const handleShowClose = () => setShow(false);
  const handleShowOpen = () => setShow(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const registrationMutation = useMutation(CreateBulkAtendanceSheet);
  const Project_id = state.data.project.id;
  const attendanceDate = state.data.date;
  const attendanceQuery = useQuery(
    ["add_labor_labor_attendance", Project_id],
    () => get_labor_attendance(Project_id, attendanceDate)
  );
  const projectLaborsQuery = useQuery(
    ["add_labor_project_labors", Project_id],
    () => get_project_labors(Project_id)
  );
  useEffect(() => {
    if (!projectLaborsQuery.data || !attendanceQuery.data) {
      // Data is still loading, don't proceed
      return;
    }
    // Check if all individual checkboxes are checked
    const allChecked = projectLaborsQuery.data.every(item =>
      selectedItems.some(selectedItem => selectedItem.id === item.id)
    );

    setSelectAll(allChecked);
  }, [selectedItems, projectLaborsQuery.data, attendanceQuery.data]);

  if (attendanceQuery.isLoading || projectLaborsQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (attendanceQuery.isError) {
    return <div>Error fetching attendance data: {attendanceQuery.error.message}</div>;
  }

  if (projectLaborsQuery.isError) {
    return <div>Error fetching project labors: {projectLaborsQuery.error.message}</div>;
  }
  // Access the data individually
  const attendanceData = attendanceQuery.data;
  const projectLaborsData = projectLaborsQuery.data;
  const transformData = () => {
    const result = selectedItems.map(item => {
        const formattedDate = state.data.date;
        return {
            project_id: item.project_id,
            job_id: item.job_id,
            labor_id: item.labor_id,
            salary: item.project_job.salary,
            job_title: item.project_job.job_title,
            date: formattedDate,
        };
    });
    return result;
};
  const addNewLabor = async() => {
    if(selectedItems.length===0){
      toast.error('Please choose at least one new labor', {
        position: 'top-right',
        autoClose: 5000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
  const dataArray=transformData();
   try {
    setIsSubmitting(true);
    await registrationMutation.mutateAsync(dataArray);
    toast.success('New labors added successfully', {
      position: 'top-right',
      autoClose: 4000, 
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    //history.push(`/labor-attendance-sheet`); 
    handleBacktoView(); 
  } catch (error) {
    setalertTitle("Error");
    setAlertMessage(error.message);
  } finally {
    setIsSubmitting(false);
  }
  };
  const handleCheckboxChange = (selectedItem) => {
    setSelectedItems((prevSelectedItems) => {
      const selectedItemIndex = prevSelectedItems.findIndex((item) => item.id === selectedItem.id);
      if (selectedItemIndex !== -1) {
        // Item is already selected, remove it
        const updatedSelectedItems = [...prevSelectedItems];
        updatedSelectedItems.splice(selectedItemIndex, 1);
        return updatedSelectedItems;
      } else {
        // Item is not selected, add it
        return [...prevSelectedItems, selectedItem];
      }
    });
  };
  const handleHeaderCheckboxChange = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setSelectedItems((prevSelectedItems) => {
      return selectAll ? [] : [...projectLaborsData];
    });
  };
  const handleBacktoView = (event) => {
    if (event) {
      event.preventDefault();
    }
    const data = {
      project: state.data.project,
      date: state.data.date
    }
    history.push('/attendance-list-view', {data});
  }


  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h6>
                
              </h6>
            </div>
          </div>
          <Row>
            <Col lg="12">
              <Card className="card-block card-stretch">
                <Card.Body className="p-0"> 
                <div className="d-flex justify-content-between align-items-center p-3">
               <h6 className="font-weight-bold"> {state.data.project.name} project labor list on {formatDate(state.data.date)} </h6>
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <Button variant="btn btn-info btn-sm m-2" size="sm" onClick={()=> handleShowOpen()}> {t("Add")}</Button>  
                   
                   <Button variant="btn btn-primary btn-sm m-2" size="sm" onClick={(e)=>handleBacktoView(e)}> {t("back")}</Button>        
             </div>
            </div>
                 <div className="table-responsive">
                    <table className="table data-table mb-0"  size="sm"> 
                      <thead className="table-color-heading"  >
                        <tr className="text-light">
                        <th scope="col" className="w-01 pr-0">#</th>
      <th scope="col" className="dates">
        <label className="text-muted mb-0">{t("full_name")}</label>
      </th>
      <th scope="col">
        <label className="text-muted mb-0">{t("role")}</label>
      </th>
      <th scope="col">
        <label className="text-muted mb-0">{t("payment")}per (hr)</label>
      </th>
       <th> Action </th>
         </tr>
                      </thead>
                      <tbody>
                        {attendanceData.map((item,index) => (
                          <tr key={item.id} className="white-space-no-wrap">
                            <td className="pr-0">
                            {index+1}
                            </td>
                            <td>{item.labor.first_name} {item.labor.father_name}</td>
                            <td>{item.job_title}</td>
                            <td>{item.salary}ETB</td>
                            <td>  
                              {item.current_status}</td>
                            </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>


      <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}/>
          <Modal show={show} onHide={handleShowClose} centered={true}>
                        <Modal.Header closeButton>
                                 <Modal.Title as="h5">Add new labor</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="table-responsive">
                    <table className="table data-table mb-0"  size="sm"> 
                      <thead className="table-color-heading"  >
                        <tr className="text-light">
                        <th scope="col" className="w-01 pr-0">
        <div className="d-flex justify-content-start align-items-end mb-1">
          <div className="custom-control custom-checkbox custom-control-inline">
          <input
                  type="checkbox"
                  className="custom-control-input m-0"
                  id="customCheck"
                  checked={selectAll}
                  onChange={handleHeaderCheckboxChange}
                  key="header-checkbox"
                />
            <label
              className="custom-control-label"
              htmlFor="customCheck"
            />
          </div>
        </div>
      </th>
      <th scope="col" className="dates">
        <label className="text-muted mb-0">{t("full_name")}</label>
      </th>
      <th scope="col">
        <label className="text-muted mb-0">{t("role")}</label>
      </th>
      <th scope="col">
        <label className="text-muted mb-0">{t("payment")}(hr)</label>
      </th>
        </tr>
           </thead>
                      <tbody>
                        {projectLaborsData.map((item) => (
                          <tr key={item.id} className="white-space-no-wrap">
                            <td className="pr-0">
                              <div className="custom-control custom-checkbox custom-control-inline">
                              <input
              type="checkbox"
              className="custom-control-input m-0"
              id={`customCheck_${item.id}`}
              checked={selectAll || selectedItems.some((selectedItem) => selectedItem.id === item.id)}
              onChange={() => handleCheckboxChange(item)}
            />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`customCheck_${item.id}`}
                                ></label>
                              </div>
                            </td>
                            <td>{item.labor.first_name} {item.labor.father_name}</td>
                            <td>{item.project_job.job_title}</td>
                            <td>{item.project_job.salary}ETB</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                        </Modal.Body>
                        <Modal.Footer>
                           <Button variant="primary" onClick={handleShowClose}>Close</Button>
                           <Button variant="success" onClick={addNewLabor}  disabled={isSubmitting}>
                      {isSubmitting ? (
                        <span>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Sending...</span>
                        </span>
                      ) : (
                        t("Add Labor")
                      )}
                      
                      </Button>
                        </Modal.Footer>
                     </Modal>
            </Container>
  );
};

export default AddLabortoProject;