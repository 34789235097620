import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {useLocation } from 'react-router-dom';
import { useMutation } from "react-query";
import regionsData from "../../../store/jsondata/regionData.json";
import employeeData from "../../../store/jsondata/employeData.json";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import { validateForm } from "../../../middleware/validateEmpReferenceForm ";
import { RegisterEmpReference } from "../../../services/employeeApi";
import AlertModal from "../../sharedComponent/AlertModal";
const EmployeeReferenceadd = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
  const [formData, setFormData] = useState({
    emp_id:id,
    first_name: "",
    father_name: "",
    last_name: "",
    sex: "male",
    birth_date: "",
    email: "",
    marital_status:"",
    job:"",
    reference_type:"",
    phone: "",
    country: "",
    region: "",
    zone: "",
    wereda: "",
    kebele: "",
    homeNumber: "",
  
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const handleInputChange = (e, field, nestedField) => {
    const value = nestedField
      ? { ...formData[field], [nestedField]: e.target.value }
      : e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
    // Clear the validation error for the field when the value changes
    setValidationErrors({
      ...validationErrors,
      [field]: "",
    });
  };
  const countries = regionsData.countries;
  const regions = regionsData.regions;
  const emp_marital_status=employeeData.marital_status;
  const emp_ref_type=employeeData.reference_type;
  const registrationMutation = useMutation(RegisterEmpReference);
  const handleRegistration = async (formData) => {
    try {
      setIsSubmitting(true);
      await registrationMutation.mutateAsync(formData);
      setalertTitle("Success");
      setAlertMessage("Registration successful");
    } catch (error) {
      setalertTitle("Error");
      setAlertMessage(error.message);
    } finally {
      setIsSubmitting(false);
      setshowAlert(true);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      handleRegistration(formData);
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/employee">{t("employee")}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`employee-view/?id=${id}`}>{t("employee_view")}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("add_employee_ref")}
                    </li>

                  </ol>
                </nav>
              </div>
              <Link
                to={`/employee-reference?id=${id}`}
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">{t("back")}</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              {" "}
              {t("add_new_ref")}
            </h4>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Form
                      className="row g-3 date-icon-set-modal"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("first_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter first Name"
                          value={formData.first_name}
                          onChange={(e) => handleInputChange(e, "first_name")}
                        />
                        {validationErrors.first_name && (
                          <div className="text-danger">
                            {validationErrors.first_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("middle_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter middle Name"
                          value={formData.father_name}
                          onChange={(e) => handleInputChange(e, "father_name")}
                        />
                        {validationErrors.father_name && (
                          <div className="text-danger">
                            {validationErrors.father_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("last_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter last Name"
                          value={formData.last_name}
                          onChange={(e) => handleInputChange(e, "last_name")}
                        />
                        {validationErrors.last_name && (
                          <div className="text-danger">
                            {validationErrors.last_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label className="font-weight-bold text-muted ">
                          {t("sex")}
                        </Form.Label>
                        <br />
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio custom-control-inline">
                            <Form.Control
                              type="radio"
                              id="inlineRadio1"
                              name="customRadio-1"
                              className="custom-control-input"
                              value="male"
                              checked={formData.sex === "male"}
                              onChange={(e) => handleInputChange(e, "sex")}
                            />
                            <Form.Label
                              className="custom-control-label"
                              htmlFor="inlineRadio1"
                            >
                              {t("male")}
                            </Form.Label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio custom-control-inline">
                            <Form.Control
                              type="radio"
                              id="inlineRadio2"
                              name="customRadio-1"
                              className="custom-control-input"
                              value="female"
                              checked={formData.sex === "female"}
                              onChange={(e) => handleInputChange(e, "sex")}
                            />
                            <Form.Label
                              className="custom-control-label"
                              htmlFor="inlineRadio2"
                            >
                              {t("female")}
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("dob")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter Birth date"
                          value={formData.birth_date}
                          onChange={(e) => handleInputChange(e, "birth_date")}
                        />
                        {validationErrors.birth_date && (
                          <div className="text-danger">
                            {validationErrors.birth_date}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("phone")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone"
                          value={formData.phone}
                          onChange={(e) => handleInputChange(e, "phone")}
                        />
                        {validationErrors.phone && (
                          <div className="text-danger">
                            {validationErrors.phone}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("email")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Email"
                          value={formData.email}
                          onChange={(e) => handleInputChange(e, "email")}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("ref_job")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter occupation"
                          value={formData.job}
                          onChange={(e) => handleInputChange(e, "job")}
                        />
                        {validationErrors.job && (
                          <div className="text-danger">
                            {validationErrors.job}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="inputmarital"
                          className="font-weight-bold text-muted "
                        >
                          {t("marital_status")}
                        </Form.Label>
                        <select
                          defaultValue=""
                          id="inputmarital"
                          className="form-select form-control choicesjs"
                          value={formData.marital_status}
                          onChange={(e) =>
                            handleInputChange(e,"marital_status")
                          }
                        >
                          <option value="" hidden disabled>
                            Select marital status
                          </option>
                          {emp_marital_status.map((data) => (
                            <option key={data.id} value={data.name}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        {validationErrors.marital_status && (
                          <div className="text-danger">
                            {validationErrors.marital_status}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="inputreftype"
                          className="font-weight-bold text-muted "
                        >
                          {t("emp_ref_type")}
                        </Form.Label>
                        <select
                          defaultValue=""
                          id="inputreftype"
                          className="form-select form-control choicesjs"
                          value={formData.reference_type}
                          onChange={(e) =>
                            handleInputChange(e,"reference_type")
                          }
                        >
                          <option value="" hidden disabled>
                            Select reference type
                          </option>
                          {emp_ref_type.map((data) => (
                            <option key={data.id} value={data.name}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        {validationErrors.reference_type && (
                          <div className="text-danger">
                            {validationErrors.reference_type}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="inputcountry"
                          className="font-weight-bold text-muted "
                        >
                          {t("country")}
                        </Form.Label>
                        <select
                          defaultValue=""
                          id="inputcountry"
                          className="form-select form-control choicesjs"
                          value={formData.country}
                          onChange={(e) =>
                            handleInputChange(e, "country")
                          }
                        >
                          <option value="" hidden disabled>
                            Select Country
                          </option>
                          {countries.map((data) => (
                            <option key={data.id} value={data.name}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        {validationErrors.country && (
                          <div className="text-danger">
                            {validationErrors.country}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="inputState"
                          className="font-weight-bold text-muted "
                        >
                          {t("region")}
                        </Form.Label>
                        <select
                          defaultValue=""
                          id="inputState"
                          className="form-select form-control choicesjs"
                          value={formData.region}
                          onChange={(e) =>
                            handleInputChange(e, "region")
                          }
                        >
                          <option value="" hidden disabled>
                            Select State/Region
                          </option>
                          {regions.map((region) => (
                            <option key={region.id} value={region.name}>
                              {region.name}
                            </option>
                          ))}
                        </select>
                        {validationErrors.region && (
                          <div className="text-danger">
                            {validationErrors.region}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text6"
                          className="font-weight-bold text-muted "
                        >
                          {t("kifle_ketema")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address"
                          value={formData.zone}
                          onChange={(e) =>
                            handleInputChange(e, "zone")
                          }
                        />
                        {validationErrors.zone && (
                          <div className="text-danger">
                            {validationErrors.zone}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text3"
                          className="font-weight-bold text-muted "
                        >
                          {t("kebele")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="Text3"
                          placeholder="Enter  kebele"
                          value={formData.kebele}
                          onChange={(e) =>
                            handleInputChange(e, "kebele")
                          }
                        />
                        {validationErrors.kebele && (
                          <div className="text-danger">
                            {validationErrors.kebele}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text7"
                          className="font-weight-bold text-muted "
                        >
                          {t("wereda")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter address"
                          value={formData.wereda}
                          onChange={(e) =>
                            handleInputChange(e, "wereda")
                          }
                        />
                        {validationErrors.wereda && (
                          <div className="text-danger">
                            {validationErrors.wereda}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text3"
                          className="font-weight-bold text-muted "
                        >
                          {t("house_number")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="Text3"
                          placeholder="Enter house number"
                          value={formData.homeNumber}
                          onChange={(e) =>
                            handleInputChange(e, "homeNumber")
                          }
                        />
                      </div>
                    
                    </Form>
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Sending...</span>
                          </span>
                        ) : (
                          t("add_employee_ref")
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmployeeReferenceadd;
