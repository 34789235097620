import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';

const SearchableDropdown = ({ options, onChange, value, placeholder }) => {
    return (
      <Form.Group>
        <Select
          options={options}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          isSearchable
        />
      </Form.Group>
    );
  };
  export default SearchableDropdown;