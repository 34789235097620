import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {useQuery,useMutation,useQueryClient } from "react-query";
import regionsData from "../../../store/jsondata/regionData.json";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import { validateForm } from "../../../middleware/validateEmployeeForm";
import { get_empDetail_dropDown_data,RegisterEmployee } from "../../../services/employeeApi";
import AlertModal from "../../sharedComponent/AlertModal";
import { toast } from 'react-toastify';
const Employeeadd = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const registrationMutation = useMutation(async (newData) => {
    await RegisterEmployee(newData);
    queryClient.invalidateQueries('employeeview');
  });

  const [formData, setFormData] = useState({
    first_name: "",
    father_name: "",
    last_name: "",
    department:"",
    sex: "male",
    birth_date: "",
    email: "",
    phone: "",
    tin_number:"",
    marital_status:"single",
    hire_date:"",
    employment_type:"",
    address: {
      country: "",
      region: "",
      zone: "",
      wereda: "",
      kebele: "",
      homeNumber: "",
    },
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const { data, isLoading, isError, error } = useQuery(["add_employee_page"], async () => {
    try {
      const dropDownData = await get_empDetail_dropDown_data();
      return { dropDownData };
    } catch (error) {
      throw new Error(`Error fetching data: ${error.message}`);
    }
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  const { dropDownData } = data;
  
 
  const handleInputChange = (e, field, nestedField) => {
    const value = nestedField
      ? { ...formData[field], [nestedField]: e.target.value }
      : e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [field]: "",
    });
  };
  const countries = regionsData.countries;
  const regions = regionsData.regions;
  const marital_status=regionsData.marital_status;
  const employment_types=regionsData.employment_type;
  const handleRegistration = async (employeeData) => {
    try {
      setIsSubmitting(true);
      await registrationMutation.mutateAsync(employeeData);
      toast.success('New employee registered successfully', {
        position: 'top-right',
        autoClose: 4000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      setshowAlert(true);
      setalertTitle("Error");
      setAlertMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      handleRegistration(formData);
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/employee">{t("employee")}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("add_employee")}
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to="/employee"
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">{t("back")}</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              {" "}
              {t("add_new_employee")}
            </h4>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Form
                      className="row g-3 date-icon-set-modal"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("first_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter first Name"
                          value={formData.first_name}
                          onChange={(e) => handleInputChange(e, "first_name")}
                        />
                        {validationErrors.first_name && (
                          <div className="text-danger">
                            {validationErrors.first_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("middle_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter middle Name"
                          value={formData.father_name}
                          onChange={(e) => handleInputChange(e, "father_name")}
                        />
                        {validationErrors.father_name && (
                          <div className="text-danger">
                            {validationErrors.father_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("last_name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter last Name"
                          value={formData.last_name}
                          onChange={(e) => handleInputChange(e, "last_name")}
                        />
                        {validationErrors.last_name && (
                          <div className="text-danger">
                            {validationErrors.last_name}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label className="font-weight-bold text-muted ">
                          {t("sex")}
                        </Form.Label>
                        <br />
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio custom-control-inline">
                            <Form.Control
                              type="radio"
                              id="inlineRadio1"
                              name="customRadio-1"
                              className="custom-control-input"
                              value="male"
                              checked={formData.sex === "male"}
                              onChange={(e) => handleInputChange(e, "sex")}
                            />
                            <Form.Label
                              className="custom-control-label"
                              htmlFor="inlineRadio1"
                            >
                              {t("male")}
                            </Form.Label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio custom-control-inline">
                            <Form.Control
                              type="radio"
                              id="inlineRadio2"
                              name="customRadio-1"
                              className="custom-control-input"
                              value="female"
                              checked={formData.sex === "female"}
                              onChange={(e) => handleInputChange(e, "sex")}
                            />
                            <Form.Label
                              className="custom-control-label"
                              htmlFor="inlineRadio2"
                            >
                              {t("female")}
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("department")}
                        </Form.Label>
                        <select
            id="inputPosition"
            className="form-select form-control choicesjs"
            value={formData.department}
            onChange={(e) => handleInputChange(e, 'department')}
          >
            <option value="" hidden disabled>
              Select Department
            </option>
            {dropDownData.department.map((data) => (
              <option key={data.id} value={data.name}>
                {data.name}
              </option>
            ))}
          </select>
         </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("dob")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter Birth date"
                          value={formData.birth_date}
                          onChange={(e) => handleInputChange(e, "birth_date")}
                        />
                        {validationErrors.birth_date && (
                          <div className="text-danger">
                            {validationErrors.birth_date}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("email")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Email"
                          value={formData.email}
                          onChange={(e) => handleInputChange(e, "email")}
                        />
                        {validationErrors.email && (
                          <div className="text-danger">
                            {validationErrors.email}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("phone")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone"
                          value={formData.phone}
                          onChange={(e) => handleInputChange(e, "phone")}
                        />
                        {validationErrors.phone && (
                          <div className="text-danger">
                            {validationErrors.phone}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted ">
                          {t("tin_number")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone"
                          value={formData.tin_number}
                          onChange={(e) => handleInputChange(e, "tin_number")}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="inputcountry"
                          className="font-weight-bold text-muted "
                        >
                          {t("marital_status")}
                        </Form.Label>
                        <select
                          defaultValue=""
                          id="inputcountry"
                          className="form-select form-control choicesjs"
                          value={formData.marital_status}
                          onChange={(e) =>
                            handleInputChange(e, "marital_status")
                          }
                        >
                          <option value="" hidden disabled>
                            Select marital status
                          </option>
                          {marital_status.map((data) => (
                            <option key={data.id} value={data.name}>
                              {data.name}
                            </option>
                          ))}
          
                        </select>
                        
                      </div>

                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="inputcountry"
                          className="font-weight-bold text-muted "
                        >
                          {t("country")}
                        </Form.Label>
                        <select
                          defaultValue=""
                          id="inputcountry"
                          className="form-select form-control choicesjs"
                          value={formData.address.country}
                          onChange={(e) =>
                            handleInputChange(e, "address", "country")
                          }
                        >
                          <option value="" hidden disabled>
                            Select Country
                          </option>
                          {countries.map((data) => (
                            <option key={data.id} value={data.name}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        {validationErrors.country && (
                          <div className="text-danger">
                            {validationErrors.country}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="inputState"
                          className="font-weight-bold text-muted "
                        >
                          {t("region")}
                        </Form.Label>
                        <select
                          defaultValue=""
                          id="inputState"
                          className="form-select form-control choicesjs"
                          value={formData.address.region}
                          onChange={(e) =>
                            handleInputChange(e, "address", "region")
                          }
                        >
                          <option value="" hidden disabled>
                            Select State/Region
                          </option>
                          {regions.map((region) => (
                            <option key={region.id} value={region.name}>
                              {region.name}
                            </option>
                          ))}
                        </select>
                        {validationErrors.region && (
                          <div className="text-danger">
                            {validationErrors.region}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text6"
                          className="font-weight-bold text-muted "
                        >
                          {t("kifle_ketema")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address"
                          value={formData.address.zone}
                          onChange={(e) =>
                            handleInputChange(e, "address", "zone")
                          }
                        />
                        {validationErrors.zone && (
                          <div className="text-danger">
                            {validationErrors.zone}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text7"
                          className="font-weight-bold text-muted "
                        >
                          {t("wereda")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter address"
                          value={formData.address.wereda}
                          onChange={(e) =>
                            handleInputChange(e, "address", "wereda")
                          }
                        />
                        {validationErrors.wereda && (
                          <div className="text-danger">
                            {validationErrors.wereda}
                          </div>
                        )}
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text3"
                          className="font-weight-bold text-muted "
                        >
                          {t("house_number")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="Text3"
                          placeholder="Enter house number"
                          value={formData.address.homeNumber}
                          onChange={(e) =>
                            handleInputChange(e, "address", "homeNumber")
                          }
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text3"
                          className="font-weight-bold text-muted "
                        >
                          {t("kebele")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="Text3"
                          placeholder="Enter  kebele"
                          value={formData.address.kebele}
                          onChange={(e) =>
                            handleInputChange(e, "address", "kebele")
                          }
                        />
                        {validationErrors.kebele && (
                          <div className="text-danger">
                            {validationErrors.kebele}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("hire_date")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter hired date"
                          value={formData.hire_date}
                          onChange={(e) => handleInputChange(e, "hire_date")}
                        />
                        {validationErrors.hire_date && (
                          <div className="text-danger">
                            {validationErrors.hire_date}
                          </div>
                        )}
                      </div>

                      <div className="col-md-4 mb-3">
                        <Form.Label
                          htmlFor="inputemployment_status"
                          className="font-weight-bold text-muted "
                        >
                          {t("employment_type")}
                        </Form.Label>
                        <select
                          defaultValue=""
                          id="inputemployment_status"
                          className="form-select form-control choicesjs"
                          value={formData.employment_type}
                          onChange={(e) =>
                            handleInputChange(e, "employment_type")
                          }
                        >
                          <option value="" hidden disabled>
                            Select employment type
                          </option>
                          {employment_types.map((data) => (
                            <option key={data.id} value={data.name}>
                              {data.name}
                            </option>
                          ))}
          
                        </select>
                        
                      </div>
                    </Form>
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Sending...</span>
                          </span>
                        ) : (
                          t("add_employee")
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Employeeadd;
