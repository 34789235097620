
import {API_BASE_URL} from '../settings/appConfig.js';
// fetchData function for making GET requests
export const fetchData = async (endpoint) => {
    const url = `${API_BASE_URL}/${endpoint}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        const errorMessage = `HTTP error! Status: ${response.status}`;
        throw new Error(errorMessage);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(error.message || 'An error occurred while fetching data.');
    }
  };
  
  // postData function for making POST requests
  export const postData = async (endpoint, requestData) => {
    const url = `${API_BASE_URL}/${endpoint}`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = await response.json();
          errorMessage = errorData.message || 'Unknown error occurred';
        } catch (jsonError) {
          errorMessage = 'An error occurred while processing the request.';
        }

        throw new Error(errorMessage);
      }
     const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(error.message || 'An error occurred while processing the POST request.');
    }
  };
  

  export const updateData = async (endpoint, requestData) => {
    const url = `${API_BASE_URL}/${endpoint}`;
    try {
      const response = await fetch(url, {
        method: 'PUT', // Use 'PUT' for update operations
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (!response.ok) {
        const errorMessage = `HTTP error! Status: ${response.status}`;
        throw new Error(errorMessage);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(error.message || 'An error occurred while processing the PUT request.');
    }
  };

  export const deleteData = async (endpoint) => {
    const url = `${API_BASE_URL}/${endpoint}`;
  
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          // Add any necessary headers for your DELETE request
        },
      });
  
      if (!response.ok) {
        const errorMessage = `HTTP error! Status: ${response.status}`;
        throw new Error(errorMessage);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(error.message || 'An error occurred while processing the DELETE request.');
    }
  };