import React, {useState, useEffect } from "react";
import {useTranslation } from "react-i18next";
import {useHistory } from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap'
import Card from '../../../components/Card'
import {get_project_attendance_sheet } from "../../../services/projectApi";
import {useQuery } from "react-query";
import {formatDate } from '../../../utils/date_utils';
import SharedModal from '../../sharedComponent/SharedContentModal';
import AttendanceSheet from './create-attendance-sheet-modal';
import {get_project_data } from "../../../services/projectApi";
import {AttendanceTime } from "../../../utils/attendance_time_utils"
const Attendance_sheet = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [selectedProject, setSelectedProject] = useState('');
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleButtonSubmit = () => {handleCloseModal();};
  const projectDataQuery = useQuery(["project_data"],() => get_project_data());
  useEffect(() => {
    if (!projectDataQuery.data) {
      // Data is still loading, don't proceed
      return;
    }
  if (projectDataQuery.data.length > 0) {
      setSelectedProject(projectDataQuery.data[0]);
    }
  }, [projectDataQuery.data]);
  const labor_attendance_sheet = useQuery(['labor_attendance_sheet', selectedProject],() =>  get_project_attendance_sheet(selectedProject.id));
  
  if (projectDataQuery.isLoading|| labor_attendance_sheet.isLoading ) {
    return <div>Loading...</div>;
  }

  if (projectDataQuery.isError) {
    return <div>Error fetching project {projectDataQuery.error.message}</div>;
  }

  if (labor_attendance_sheet.isError) {
    return <div> Error fetching attendance sheet data {labor_attendance_sheet.error.message}</div>;
  }
  const projectData=projectDataQuery.data;
   const attendanceSheet = labor_attendance_sheet.data;
  const openCreatSheetModal = (event) => {
    event.preventDefault();
    handleShowModal();
    setModalTitle("create_labor_attendance_sheet");
    setContent(<AttendanceSheet onSubmitButton={handleButtonSubmit} projectID={selectedProject.id} />);
  };
  const AttendanceTypes = ['morningattendance', 'afternoonattendance', 'overtimeattendance', "holidayattendance"];
  const addAttendance = (date, eventKey) => {
    if (!date) {
      alert("You have no date inorder to fill attendance ");
      return;
    }
    const data = {
      project: selectedProject.id,
      projectName:selectedProject.name,
      attendaceType: eventKey,
      attendanceName: AttendanceTime(eventKey),
      date: date,
    };
    history.push('/attendance-record', { data });
  };

  const handleView = (date) => {
    const data = {
      project: selectedProject,
      date: date
    }
    history.push('/attendance-list-view', {data});
  }
  const handleProjectOptionChange = (projectId) => {
  const selected = projectData.filter(project => project.id === Number(projectId));
  const currentSelectedProject = selected.length > 0 ? selected[0] : null;
  setSelectedProject((prevSelected) => {
  return currentSelectedProject;
  });
  }
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="12">
                <Card className="card-block card-stretch">
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h6 className="font-weight-bold"> {selectedProject.name} project {t("labor_attendance")} sheet </h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between"> 
                        <select className="form-select form-control-sm m-2" onChange={(e) => handleProjectOptionChange(e.target.value)}
                           value={selectedProject ? selectedProject.id : ''}>
                          <option value="" hidden disabled>
                            Select project
                          </option>
                          {projectData && projectData.map((data) => (
                            <option key={data.id} value={data.id}>
                              {data.name}
                            </option>
                          ))}
                        </select>
                        <Button variant="btn btn-info btn-sm m-2" onClick={(e) => openCreatSheetModal(e)}>
                          {t("creat_labor_attendance_sheet")}
                        </Button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered table-responsive-md table-striped text-center mb-0" size="sm">
                        <thead>
                          <tr className="table-primary">
                            <th>#</th>
                            <th>
                              {t("date")}
                            </th>
                            <th>
                              {t("labor")}
                            </th>
                            <th>
                            {t("morning")}
                            </th>
                            <th>
                              {t("afternoon")}

                            </th>
                            <th>
                              {t("over_time")}
                            </th>
                            <th>
                              {t("holiyday")}
                            </th>
                            <th>
                              {t("total")}
                            </th>
                            <th>
                            {t("action")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {attendanceSheet.length === 0 ? (
                  <tr>
              <td colSpan="9" className="text-center">Currently no records found
              <Button variant="btn btn-info btn-sm m-2" size="sm" onClick={() => handleView(new Date())}> View the past </Button>
              </td>
                  </tr>
                        ) : (
                          attendanceSheet.map((item, index) => (
                            <tr key={item.cust} className="white-space-no-wrap table-active">
                              <td className="pr-0">{index + 1}</td>
                              <td>{formatDate(item.date)}</td>
                              <td> {item.totalrecords}</td>
                              <td>{item.totalmornings.toFixed(2)}</td>
                              <td>{item.totalafternoons.toFixed(2)}</td>
                              <td>{item.totalover_times.toFixed(2)}</td>
                              <td>{item.totalholidays.toFixed(2)}</td>
                              <td>{item.grandtotal.toFixed(2)}</td>
                              <td>
                                  <Button variant="btn" onClick={() => handleView(item.date)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                  </Button>
                                 <select onChange={(event) => addAttendance(item.date, event.target.value)}
                                    style={{ overflow: 'inherit', zIndex: 9999 }}>
                                    <option value="">{t("add_attendance")}</option>
                                    {AttendanceTypes.map((item, index) => (
                                      <option key={index} value={index.toString()}>
                                        {t(item)}
                                      </option>
                                    ))}
                                  </select>
                              </td>
                            </tr>
                          ))
                          
                        )
                       }
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <SharedModal
        show={showModal}
        title={modalTitle}
        onHide={handleCloseModal}
        centered={true}
        backdrop="static"
        keyboard={false}
        content={content}
      />
    </>
  )
}
export default Attendance_sheet;
