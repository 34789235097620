import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { get_labor_data } from "../../../services/employeeApi";
import {formatDate } from '../../../utils/date_utils';
import {Container,Row,Col,Button} from "react-bootstrap";
import SharedModal from '../../sharedComponent/SharedContentModal';
import AssignProjectForm from '../project/assignProject';
import {useQuery } from "react-query";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import CustomPagination from "../../../utils/CustomPagination"
const LaborList = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [laborId, setLaborId] = useState();
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleButtonSubmit = () => {
        handleCloseModal();
};

  const { data, isLoading, isError, error } = useQuery("labor_list", async () => {
    try {
      const [labordata,projectdata,projectJobs] = await Promise.all([
        get_labor_data(),
      ]);
      return {labordata,projectdata,projectJobs};
    } catch (error) {
      throw new Error(`Error fetching data: ${error.message}`);
    }
  });
  const [currentPage, setCurrentPage] = useState(1);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  const { labordata} = data || {};
  const itemsPerPage =10; 
  const totalPages = Math.ceil(labordata.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };
 const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = labordata.slice(startIndex, endIndex);
  const maxVisiblePages = 5; 
  const openAssignProjectModal = (event, id) => {
    event.preventDefault();
    handleShowModal();
    setLaborId(id);
  };

  return (
    <div>

      <Container fluid>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="12">
                <Card className="card-block card-stretch">
                  <Card.Body className="p-0">
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h5 className="font-weight-bold"> {t("labor_list")}</h5>
                      <Link
                        to="/labor-add"
                        className="btn btn-sm btn-primary position-relative d-flex align-items-center justify-content-between"
                      >
                        {t("add_labor")}
                      </Link>
                    </div>
                    <div className="table-responsive">
                      <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                          <tr className="text-light">
                            <th scope="col" className="w-01 pr-0">
                              #
                            </th>

                            <th scope="col" className="dates">
                              <label className="text-muted mb-0">
                                {t("full_name")}
                              </label>
                            </th>
                            <th scope="col" className="">
                              <label className="text-muted mb-0">
                                {t("phone")}
                              </label>
                            </th>
                            <th scope="col" className="">
                              <label className="text-muted mb-0">
                                {t("sex")}
                              </label>
                            </th>
                            <th scope="col">
                              <label className="text-muted mb-0">
                                {t("project")}
                              </label>
                            </th>
                            <th scope="col" className="">
                              <label className="text-muted mb-0">
                                {t("registration_date")}
                              </label>
                            </th>
                            
                            <th scope="col" className="text-right">
                              <span className="text-muted">{t("action")}</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((item, rowCounter) => (
                            <tr key={item.cust} className="white-space-no-wrap">
                              <td className="pr-0">
                                {startIndex + rowCounter + 1}
                              </td>
                              <td className="">
                                <div className="active-project-1 d-flex align-items-center mt-0 ">
                                  <div className="data-content">
                                   
                                      <span className="font-weight-bold">
                                        {item.first_name} {item.father_name}
                                      </span>
                                  </div>
                                </div>
                              </td>
                              <td>{item.phone}</td>
                              <td>{item.sex}</td>
                              <td>{item.project_labor[0]==null?0:item.project_labor.length}</td>
                              <td>{formatDate(item.createdAt)}</td>
                              <td>
                                <div className="d-flex justify-content-end align-items-center">
                                 
                                  <Button variant="btn btn-info btn-sm" onClick={(e)=>openAssignProjectModal(e,item.id)}>
                                         {t("assign_project")}
                                    </Button>
                                    
                                  
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    <CustomPagination
                     currentPage={currentPage}
                     totalPages={totalPages}
                     onPageChange={handlePageChange}
                     maxVisiblePages={maxVisiblePages}
                     />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <SharedModal
       show={showModal}
       title="select_project_and_assign"
        onHide={handleCloseModal}
        centered={true}
        backdrop="static"
        keyboard={false}
        content={<AssignProjectForm onSubmitButton={handleButtonSubmit} parentID={laborId} />}
              />
    </div>
  );
};
export default LaborList;
