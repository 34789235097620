import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {Container,Row,Col,Button,ListGroup,Tab,Nav} from 'react-bootstrap'
import {useLocation } from 'react-router-dom';
import {formatDate } from '../../../utils/date_utils';
import SharedModal from '../../sharedComponent/SharedContentModal';
import EmplpoyeeDetailRegistrationForm from './EmppolyeeDetailRegistrationForm';
import  Card from '../../../components/Card'
import {Link} from 'react-router-dom'
import {get_employee,get_employeeDetail} from "../../../services/employeeApi";
import {useQuery } from "react-query";
import user1 from '../../../assets/images/user/1.jpg'
const Employeeview =()=>{
    const { t } = useTranslation();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleButtonSubmit = () => {
          handleCloseModal();
  };
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const {data, isLoading, isError, error } = useQuery(["employeeview", id], async () => {
  const [employee, employeeDetail] = await Promise.all([
    get_employee(id),
    get_employeeDetail(id)
  ]);
  return {employee, employeeDetail };
});

if (isLoading) {
  return <div>Loading...</div>;
}

if (isError) {
  return <div>Error: {error.message}</div>;
}
    const { employee, employeeDetail } = data;
    
    const addEmployeDetail = (event, id) => {
        event.preventDefault();
        handleShowModal();
      };
  
    return(
        <>
<SharedModal
       show={showModal}
       title="emp_detail_registration_form"
        onHide={handleCloseModal}
        centered={true}
        backdrop="static"
        keyboard={false}
        content={<EmplpoyeeDetailRegistrationForm onSubmitButton={handleButtonSubmit} parentID={id} />}
              />
           <Container fluid>
        <Row>            
            <Col lg="12">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                   <div className="d-flex align-items-center justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb p-0 mb-0">
                                <li className="breadcrumb-item"><Link to="/employee">{t("employee")}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{t("employee_view")}</li>
                            </ol>
                        </nav>
                    </div>    
                    <Link to="/employee" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                        </svg>
                        <span className="ml-2">{t("back")}</span>
                    </Link>                               
                </div>
            </Col>
            <Col lg="12" className="mb-3 d-flex justify-content-between">
                <h4 className="font-weight-bold d-flex align-items-center">{t("employee_view")}</h4>
            </Col>
        </Row>
       
        <Row>
            <Col lg="4">
                <Card>
                    <ListGroup as="ul" className="list-group-flush">
                        <ListGroup.Item as="li">
                            <div>
                                <ListGroup as="ul"  className="list-style-1 mb-0">
                                    <ListGroup.Item as="li" className="d-flex justify-content-start align-items-center">
                                        <div className="avatar">
                                            <img className="avatar avatar-img avatar-60 rounded-circle" src={user1} alt="01.jpg" />                        
                                        </div>
                                        <div className="list-style-detail ml-4 mr-2">
                                            <h6 className="font-weight-bold">{employee.first_name+" " + employee.father_name +" " + employee.last_name}</h6>
                                            <p className="mb-0 mt-1 text-muted">{employee.phone}</p>
                                        </div>                                            
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                            <Row className="mt-3">
                                
                                <Col xs="6" className="text-center">
                                <Link to={"/user-profile-edit"}>
                                    <Button variant="btn btn-block btn-sm btn-info">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                        </svg>
                                        <span className="">Edit Profile</span>
                                    </Button>
</Link>
                                   
                                </Col>
                                <Col xs="6" className="text-center mb-2">
                                <Link to={`/employee-reference?id=${id}`}>
                                    <Button variant="btn btn-block btn-sm btn-primary">
                                         <span className="">{t("employee_reference")}</span>
                                    </Button>

                                
   
                                     </Link>
                                    
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item as="li">
                            <table className="table table-borderless mb-0">
                            <tbody>
                          
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("email")}</p>                                        
                                    </td>
                                    <td>
                                        <p className="mb-0 ">{employee.email}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("dob")}</p>                                        
                                    </td>
                                    <td>
                                        <p className="mb-0 ">{ formatDate(employee.birth_date)}</p>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("sex")}</p>                                        
                                    </td>
                                    <td>
                                        <p className="mb-0 ">{employee.sex}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("country")}</p>                                        
                                    </td>
                                    <td>
                                        {employee && employee.employee_address && (
                                          <p className="mb-0">{employee.employee_address.country}</p>
                                         )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("region")}</p>                                        
                                    </td>
                                    <td>
                                    {employee && employee.employee_address && (
                                        <p className="mb-0 ">{employee.employee_address.region}</p>
                                    )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("kifle_ketema")}</p>                                        
                                    </td>
                                    <td>
                                    {employee && employee.employee_address && (
                                        <p className="mb-0 ">{employee.employee_address.kifle_ketema}</p>
                                    )}
                                        </td>
                                </tr>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("wereda")}</p>                                        
                                    </td>
                                    <td>
                                    {employee && employee.employee_address && (
                                        <p className="mb-0 ">{employee.employee_address.wereda}</p>
                                    )}
                                        </td>
                                </tr>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("kebele")}</p>                                        
                                    </td>
                                    <td>
                                    {employee && employee.employee_address && (
                                        <p className="mb-0 ">{employee.employee_address.kebele}</p>
                                   
                                         )}
                                              </td>
                                </tr>
                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("house_number")}</p>                                        
                                    </td>
                                    <td>
                                    {employee && employee.employee_address && (
                                        <p className="mb-0 ">{employee.employee_address.house_number}</p>
                                    
                                        )}    
                                        
                                        </td>
                                </tr>

                                <tr>
                                    <td className="p-0">
                                        <p className="mb-0 text-muted">{t("hire_date")}</p>                                        
                                    </td>
                                    <td>
                                        <p className="mb-0 "> {formatDate(employee.hire_date)}</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="8">
                <Card>
                    <Card.Body className="p-0">
                        <Tab.Container defaultActiveKey="first">
                        <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                            <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="first" bsPrefix="font-weight-bold  px-5 py-2 " >{t("employee_detail")}</Nav.Link></Nav.Item>
                            <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="second" bsPrefix="font-weight-bold  px-5 py-2 " >{t("employee_experience")}</Nav.Link></Nav.Item>
                            <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="third" bsPrefix="font-weight-bold  px-5 py-2" >{t("education_detail")}</Nav.Link></Nav.Item>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="first" className=" fade show  ">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5>{t("employee_detail")}</h5>
                                    <Button variant="btn btn-primary btn-sm" onClick={(e)=>addEmployeDetail(e,id)}>
                                         {t("add_employee_detail")}
                                    </Button>
                                </div>
                                <div className="table-responsive">
                                    <table className="table data-table mb-0">
                                        <thead className="table-color-heading">
                                            <tr className="text-muted">
                                            <th scope="col">{t("branch")}</th>
                                            <th scope="col">{t("position")} </th>
                                            <th scope="col">{t("grade")}</th>
                                            <th scope="col">{t("step")}</th>
                                            <th scope="col" className="text-right">{t("salary")}</th>
                                            <th scope="col">{t("add_detail_reason")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employeeDetail.map((data)=>(
                                            <tr>
                                                <td>{data.branch.name}</td>
                                                <td>{data.position.name}</td>
                                                <td>
                                                {data.grade.name}
                                                </td>
                                                <td>
                                                {data.step.name}
                                                </td>
                                                <td className="text-right">{data.salary !== null ? data.salary.toLocaleString() : 'N/A'}</td>
                                                <td>
                                                {t(data.update_reason)}
                                                </td>
                                               
                                            </tr>
                                            ))}                                            
                                        </tbody>
                                    </table>
                                </div>
                            </Tab.Pane>


                            <Tab.Pane eventKey="second" className="fade show">                                
                               
                                <div className="d-flex justify-content-between align-items-center p-3">
                                <h5 className="mb-3"> {t("employee_experience")} </h5>
                                    <Link to={`/employee-experience-add?id=${id}`} variant="btn btn-primary btn-sm">
                                         {t("add_employee_experience")}
                                    </Link>
                                </div>
                                <div className="iq-timeline0 m-0 d-flex align-items-center justify-content-between position-relative">
                                
                                <ul className="list-inline p-0 m-0">
                                {employee.emp_experiences.map((data)=>(
                                    <div>
                                        <li>
                                        <div className="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                        <h6 className="float-left mb-1 font-weight-bold">{data.position}<b> @ {data.employer} </b> <i> {data.work_place}</i></h6> 
                                      
                                        <p className="d-inline-block w-100 font-weight-bold">
                                             {formatDate(data.from_date)} - {formatDate(data.to_date)}</p> 
                                            <div className="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                            <h6 className="float-left mb-1 font-weight-bold">Job Description</h6>
                                            <div className="d-inline-block w-100">
                                                <p className="mb-0">{data.Job_description}</p>
                                            </div>

                                           <div className="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                            <h6 className="float-left mb-1 font-weight-bold">Achievements</h6>
                                            <div className="d-inline-block w-100">
                                                <p>{data.achievements}</p>
                                            </div>
                                            <div className="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                            <h6 className="float-left mb-1 font-weight-bold">Relevant Skills</h6>
                                            <div className="d-inline-block w-100">
                                                <p className="mb-0">{data.relevant_skills}</p>
                                            </div>
                                           
                                            
                                           

                                        </li>
                                 </div>
                                  ))}
                                  </ul>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="third" className="  fade show">                                
                                <div className="d-flex justify-content-between align-items-center p-3">
                                <h5 className="mb-3">{t("education_detail")}</h5>
                                    <Link to={`/employee-education-add?id=${id}`} variant="btn btn-primary btn-sm">
                                         {t("add_employee_education")}
                                    </Link>
                                </div>
                                <div className="iq-timeline0 m-0 d-flex align-items-center justify-content-between position-relative">
                                <ul className="list-inline p-0 m-0">
                                {employee.emp_educations.map((data)=>(
                                    <div>
                                        <li>
                                        <div className="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                        <h6 className="float-left mb-1 font-weight-bold">{data.major_field}<b> @ {data.educational_institution} </b> <i> {data.institution_location}</i></h6> 
                                      
                                        <p className="d-inline-block w-100 font-weight-bold">
                                             {formatDate(data.from_date)} - {formatDate(data.graduation_year)}</p> 
                                            <div className="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                            <h6 className="float-left mb-1 font-weight-bold">Education Level</h6>
                                            <div className="d-inline-block w-100">
                                                <p className="mb-0">{data.education_level}</p>
                                            </div>

                                           <div className="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                            <h6 className="float-left mb-1 font-weight-bold">Academic Result(performance)</h6>
                                            <div className="d-inline-block w-100">
                                                <p>{data.academic_result}</p>
                                            </div>
                                            <div className="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                            <h6 className="float-left mb-1 font-weight-bold">Award(Degree/Certificate)</h6>
                                            <div className="d-inline-block w-100">
                                                <p className="mb-0">{data.award}</p>
                                            </div>
                                           
                                            
                                           

                                        </li>
                                 </div>
                                  ))}
                                  </ul>

                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                        </Tab.Container>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
        </>
    )
}
export default Employeeview;