import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {useLocation } from 'react-router-dom';
import { useMutation } from "react-query";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import { validateForm } from "../../../middleware/validateEempEducationForm";
import {RegisterEmpEducation } from "../../../services/employeeApi";
import AlertModal from "../../sharedComponent/AlertModal";
const Employeeadd = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [formData, setFormData] = useState({
    emp_id:id,
    major_field:"",
    educational_institution:"",
    education_level:"",
    academic_result: "",
    from_date:"",
    graduation_year:"",
    institution_location:"",
    award:""
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const handleInputChange = (e, field, nestedField) => {
    const value = nestedField
      ? { ...formData[field], [nestedField]: e.target.value }
      : e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
    // Clear the validation error for the field when the value changes
    setValidationErrors({
      ...validationErrors,
      [field]: "",
    });
  };
  const registrationMutation = useMutation(RegisterEmpEducation);
  const handleRegistration = async (formData) => {
    try {
      setIsSubmitting(true);
      await registrationMutation.mutateAsync(formData);
      setalertTitle("Success");
      setAlertMessage("Registration successful");
    } catch (error) {
      setalertTitle("Error");
      setAlertMessage(error.message);
    } finally {
      setIsSubmitting(false);
      setshowAlert(true);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      handleRegistration(formData);
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/employee">{t("employee")}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`employee-view/?id=${id}`}>{t("employee_view")}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("add_employee_education")}
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to={`employee-view/?id=${id}`}
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">{t("back")}</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              {t("add_new_employee_education")}
            </h4>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Form
                      className="row g-3 date-icon-set-modal"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("major_field")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter field of study"
                          value={formData.major_field}
                          onChange={(e) => handleInputChange(e, "major_field")}
                        />
                        {validationErrors.major_field && (
                          <div className="text-danger">
                            {validationErrors.major_field}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("educational_institution")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Institution Name"
                          value={formData.educational_institution}
                          onChange={(e) => handleInputChange(e, "educational_institution")}
                        />
                        {validationErrors.educational_institution && (
                          <div className="text-danger">
                            {validationErrors.educational_institution}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("institution_location")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Institution Location"
                          value={formData.institution_location}
                          onChange={(e) => handleInputChange(e, "institution_location")}
                        />
                        {validationErrors.institution_location && (
                          <div className="text-danger">
                            {validationErrors.institution_location}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("education_level")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Education Level"
                          value={formData.education_level}
                          onChange={(e) => handleInputChange(e, "education_level")}
                        />
                        {validationErrors.education_level && (
                          <div className="text-danger">
                            {validationErrors.education_level}
                          </div>
                        )}
                      </div>
                    
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("from_date")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter  date"
                          value={formData.from_date}
                          onChange={(e) => handleInputChange(e, "from_date")}
                        />
                        {validationErrors.from_date && (
                          <div className="text-danger">
                            {validationErrors.from_date}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("graduation_year")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter  date"
                          value={formData.graduation_year}
                          onChange={(e) => handleInputChange(e, "graduation_year")}
                        />
                        {validationErrors.graduation_year && (
                          <div className="text-danger">
                            {validationErrors.graduation_year}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("academic_result")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Academic Result"
                          value={formData.academic_result}
                          onChange={(e) => handleInputChange(e, "academic_result")}
                        />
                        {validationErrors.academic_result && (
                          <div className="text-danger">
                            {validationErrors.academic_result}
                          </div>
                        )}
                      </div>

                    

                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("award")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter award"
                          value={formData.award}
                          onChange={(e) => handleInputChange(e, "award")}
                        />
                        {validationErrors.award && (
                          <div className="text-danger">
                            {validationErrors.award}
                          </div>
                        )}
                      </div>

                    </Form>
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Sending...</span>
                          </span>
                        ) : (
                          t("add_employee_education")
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Employeeadd;
