import React, { useState, useEffect } from "react";
import {useTranslation } from "react-i18next";
import {Container, Row, Col, Button,Spinner} from "react-bootstrap";
import {Link} from 'react-router-dom'
import Card from "../../../components/Card";
import {useHistory,useLocation } from "react-router-dom";
import {GetDatesBetween, formatDate } from "../../../utils/date_utils";
import {get_project_labors} from "../../../services/projectApi";
import {useQuery,useMutation } from "react-query";
import AlertModal from "../../sharedComponent/AlertModal";
import {CreateBulkAtendanceSheet} from "../../../services/projectApi"
import {toast} from 'react-toastify';
const CreateLaborAttendanceSheet = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [dates, setDates] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const registrationMutation = useMutation(CreateBulkAtendanceSheet);
  useEffect(() => {
    const fetchAndSetDates = () => {
      const newDates = GetDatesBetween(
        state.attendanceParam.formData.start_date,
        state.attendanceParam.formData.end_date
      );
      setDates(newDates);
    };

    if (
      state &&state.attendanceParam
    ) {
      fetchAndSetDates();
    }
  }, [state]);
  const Project_id=state.attendanceParam.formData.project_id;
  const {data, isLoading, isError, error } = useQuery(["project_labors",Project_id], () =>
  get_project_labors(Project_id)
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  const handleExport = async() => {
    if(selectedOrders.length===0){
      setalertTitle("Error");
      setAlertMessage("Please Choose at least one assigned worker");
      setshowAlert(true);
      return;
    }
   const selectedData = data.filter(item => selectedOrders.includes(item.id));
   const sheetdataArray = dates.map((d, index) => {
     const sheetdata = selectedData.map(item => ({
       project_id: item.project_id,
       job_id: item.job_id,
       labor_id: item.labor_id,
       salary: item.project_job.salary,
       job_title: item.project_job.job_title,
       date: d.toISOString(),
     }));
     return sheetdata;
   });
   const dataArray = sheetdataArray.reduce((acc, innerArray) => acc.concat(innerArray), []);
   try {
    setIsSubmitting(true);
    await registrationMutation.mutateAsync(dataArray);
    toast.success('Attendance sheet created successfully', {
      position: 'top-right',
      autoClose: 4000, 
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    history.push(`/labor-attendance-sheet`);  
  } catch (error) {
    setalertTitle("Error");
    setAlertMessage(error.message);
  } finally {
    setIsSubmitting(false);
    setshowAlert(true);
  }
  };
  const handleCheckboxChange = (orderId) => {
    setSelectedOrders((prevSelectedOrders) => {
      return prevSelectedOrders.includes(orderId)
        ? prevSelectedOrders.filter((id) => id !== orderId)
        : [...prevSelectedOrders, orderId];
    });
  };
  const handleHeaderCheckboxChange = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setSelectedOrders((prevSelectedOrders) => {
      return selectAll ? [] : data.map((item) => item.id);
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h6>
                
              </h6>
            </div>
          </div>
          <Row>
            <Col lg="12">
              <Card className="card-block card-stretch">
                <Card.Body className="p-0"> 
                <div className="d-flex justify-content-between align-items-center p-3">
               <h6 className="font-weight-bold"> Create Sheet for {dates.length} days from {formatDate(dates[0])} to {formatDate(dates[dates.length - 1])} for {state.attendanceParam.project.name} Project </h6>
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <Button variant="btn btn-info btn-sm m-2"
                      size="sm"
                      onClick={handleExport}  disabled={isSubmitting}>
                      {isSubmitting ? (
                        <span>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">Sending...</span>
                        </span>
                      ) : (
                        t("creat_sheet")
                      )}
          </Button>
                <Link className=""  to="/labor-attendance-sheet"> 
          <Button variant="primary"size="sm">
          {t("back")}
          </Button>
          </Link> 
             </div>
            </div>
                  <div className="table-responsive">
                    <table className="table data-table mb-0"  size="sm"> 
                      <thead className="table-color-heading"  >
                        <tr className="text-light">
                        <th scope="col" className="w-01 pr-0">
        <div className="d-flex justify-content-start align-items-end mb-1">
          <div className="custom-control custom-checkbox custom-control-inline">
            <input
              type="checkbox"
              className="custom-control-input m-0"
              id="customCheck"
              checked={selectAll}
              onChange={handleHeaderCheckboxChange}
              key="header-checkbox"
            />
            <label
              className="custom-control-label"
              htmlFor="customCheck"
            />
          </div>
        </div>
      </th>
      <th scope="col" className="dates">
        <label className="text-muted mb-0">{t("full_name")}</label>
      </th>
      <th scope="col">
        <label className="text-muted mb-0">{t("role")}</label>
      </th>
      <th scope="col">
        <label className="text-muted mb-0">{t("payment")}(hr)</label>
      </th>
          </tr>
                      </thead>
                      <tbody>
                        {data.map((item) => (
                          <tr key={item.id} className="white-space-no-wrap">
                            <td className="pr-0">
                              <div className="custom-control custom-checkbox custom-control-inline">
                                <input
                                  type="checkbox"
                                  className="custom-control-input m-0"
                                  id={`customCheck_${item.id}`}
                                  checked={selectedOrders.includes(item.id)}
                                  onChange={() => handleCheckboxChange(item.id)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`customCheck_${item.id}`}
                                ></label>
                              </div>
                            </td>
                            <td>{item.labor.first_name} {item.labor.father_name}</td>
                            <td>{item.project_job.job_title}</td>
                            <td>{item.project_job.salary}ETB</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
    </Container>
  );
};

export default CreateLaborAttendanceSheet;