import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {useQuery,useMutation,useQueryClient} from "react-query";
import { Form, Button ,Alert,Spinner} from 'react-bootstrap';
import AlertModal from "../../sharedComponent/AlertModal";
import {get_project_data,get_project_jobs,AssignProjectLabor} from "../../../services/projectApi"
import {toast} from 'react-toastify';
const AssignProjectForm = ({ parentID, onSubmitButton }) => {
    const { t } = useTranslation();
    const [formerror, setFormError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAlert, setshowAlert] = useState();
    const handleClose = () => setshowAlert(false);
    const [AlertMessage, setAlertMessage] = useState("");
    const [alertTitle, setalertTitle] = useState("");
    const [formData, setFormData] = useState({
      labor_id: parentID,
      project_id: "",
      job_id: "",
      status: 1,
      updated_by: "melkamu",
    });
       const queryClient = useQueryClient();
        const registrationMutation = useMutation(async (data) => {
          await AssignProjectLabor(data);
          queryClient.invalidateQueries('labor_list');
        });
    const { data, isLoading, isError, error } = useQuery("assign_labor_project_data", async () => {
        try {
        const [projectdata,projectJobs] = await Promise.all([
            get_project_data(),
            get_project_jobs()
          ]);
        return {projectdata,projectJobs};
        } catch (error) {
          throw new Error(`Error fetching data: ${error.message}`);
        }
      });
    if (isLoading) {
      return <div>Loading...</div>;
    }
    if (isError) {
      return <div>Error: {error.message}</div>;
    }
    const {projectdata,projectJobs } = data;
    const handleRegistration = async (Data) => {
      try {
        setIsSubmitting(true);
        await registrationMutation.mutateAsync(Data); 
        toast.success('Assigned successfully', {
          position: 'top-right',
          autoClose: 4000, 
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        handleCloseModal();
      } catch (error) {
        setshowAlert(true);
        setalertTitle("Error");
        setAlertMessage(error.message);
      } finally {
        setIsSubmitting(false);
      }
    };
    
    const handleSubmit = (e) => {
      e.preventDefault();
      const requiredFields = ['job_id','project_id', 'labor_id'];
      const isEmptyField = requiredFields.some(field => !formData[field]);
      if (isEmptyField) {
        setFormError(true);
        return;
      }
      handleRegistration(formData);
    };
  
    const handleCloseModal = () => {
      onSubmitButton();
    };
  

    const handleInputChange = (e, fieldName) => {
      const { value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
      }));
    };
  
    return (  
       <>

      <Form onSubmit={handleSubmit}>
        {formerror?(
        <Alert variant="danger"><p>Please tray again all fields are required.</p> </Alert>
        ):""}
        <Form.Group>
          <Form.Label htmlFor="inputReason" className="font-weight-bold text-muted">
            {t("project")}
          </Form.Label>
          <select
            id="inputReason"
            className="form-select form-control choicesjs"
            value={formData.project_id}
            onChange={(e) => handleInputChange(e, 'project_id')}
          >
            <option value="" hidden disabled>
              Select project
            </option>
           {projectdata && projectdata.map((data) => (
          <option key={data.id} value={data.id}>
              {data.name}
            </option>
          ))}
          </select>
        </Form.Group>
  
        <Form.Group>
          <Form.Label htmlFor="inputPosition" className="font-weight-bold text-muted">
            {t("job_role")}
          </Form.Label>
          <select
            id="inputPosition"
            className="form-select form-control choicesjs"
            value={formData.job_id}
            onChange={(e) => handleInputChange(e,'job_id')}
          >
            <option value="" hidden disabled>
              Select job 
            </option>
            {projectJobs && projectJobs.map((data) => (
            <option key={data.id} value={data.id}>
                   {data.job_title}
            </option>
            ))}
          </select>
        </Form.Group>

        <div className="d-flex mt-1">
          <div className="mb-1">
            <Button variant="primary" type="submit"  disabled={isSubmitting}>
                      
                        {isSubmitting ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Sending...</span>
                          </span>
                        ) : (
                          t("register")
                        )}
            </Button>
          </div>
          <div className="col-md-6 mb-1">
            <Button variant="primary" onClick={handleCloseModal}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Form>
      <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
    </>
     );
  };
  

export default AssignProjectForm;
