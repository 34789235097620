// userSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import {postData} from '../../utils/apiUtils';
const persistConfig = {
  key: 'root',
  storage,
};
 const login = createAsyncThunk('auth/login', async (credentials) => {
  const url = `employee/user/login`;
  const responseData = await postData(url,credentials);
  return responseData;
});

const logout = () => {
  // Perform any necessary actions to log out the user locally
  return { type: 'auth/logout' };
};
const initialState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  userInfo: {}, // for user object
  userToken: null, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.

};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.userToken = action.payload.token;
      state.userInfo = action.payload.userInfo;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase('auth/logout', (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.userToken = null;
      state.userInfo = {};
    });
  },
});

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);
export { login, logout };
export default persistedAuthReducer;


