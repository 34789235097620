import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//store

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
//reducer
import { store, persistor } from './store'
import './i18n';
import * as serviceWorkerRegistration from "./workerservice/serviceWorkerRegistration";
const queryClient = new QueryClient();
ReactDOM.render(
  <React.Fragment>
     <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
            <App />
            </QueryClientProvider>
            </PersistGate>
        </Provider>
      </BrowserRouter>
      <ToastContainer />
  </React.Fragment>,
  document.getElementById('root')
);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
