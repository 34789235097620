// validation.js
export const validateForm = (formData) => {
    const errors = {};
    if (!formData.first_name.trim()) {
      errors.first_name = "First Name is required";
    }
  
    if (!formData.father_name.trim()) {
      errors.father_name = "Father Name is required";
    }
    if (!formData.last_name.trim()) {
        errors.last_name = "Last Name is required";
      }  
    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!isValidPhone(formData.phone)) {
      errors.phone = "Invalid phone number format";
    }

    return errors;
  };
  
  export const isValidPhone = (phone) => {
    // Basic phone number validation regex, you can enhance it
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };
  
  