const lang = {
    edit: "Rediger",
    save: "Gem",
    cancel: "Annuller",
    delete: "Slet",
    create: "Opret",
    update: "Opdater",
    search: "Søg",
    select: "Vælg",
    view: "Vis",
    submit: "Indsend",
    add: "Tilføj",
    remove: "Fjern",
    close: "Luk",
    next: "Næste",
    previous: "Forrige",
    filter: "Filter",
    sort: "Sorter",
    clear: "Ryd",
    manage: "Administrer",
    import: "Importér",
    export: "Eksportér",
    download: "Download",
    upload: "Upload",
    print: "Udskriv",
    send: "Send",
    receive: "Modtag",
    approve: "Godkend",
    reject: "Afvis",
    complete: "Fuldfør",
    confirm: "Bekræft",
    error: "Fejl",
    information: "Information",
    status: "Status",
    settings: "Indstillinger",
    preferences: "Præferencer",
    notification: "Notifikation",
    navigation: "Navigation",
    home: "Hjem",
    dashboard: "Dashboard",
    profile: "Profil",
    user: "Bruger",
    account: "Konto",
    supplier: "Leverandør",
    product: "Produkt",
    order: "Ordre",
    payment: "Betaling",
    report: "Rapport",
    analytics: "Analyse",
    audit: "Revision",
    email: "Email",
    phone: "Telefon",
    address: "Adresse",
    city: "By",
    state: "Stat",
    country: "Land",
    task: "Opgave",
    schedule: "Planlæg",
    calendar: "Kalender",
    logout: "Log ud",
    overview: "Oversigt",
    details: "Detaljer",
    notes: "Noter",
    help: "Hjælp",
    about: "Om",
    language: "Sprog",
    timezone: "Tidszone",
    date: "Dato",
    time: "Tid",
    quantity: "Mængde",
    price: "Pris",
    amount: "Beløb",
    discount: "Rabat",
    subtotal: "Subtotal",
    total: "Total",
    shipped: "Afsendt",
    delivered: "Leveret",
    pending: "Afventer",
    cancelled: "Annulleret",
    returned: "Returneret",
    exchange: "Ombytning",
    refund: "Refusion",
    login: "Log ind",
    register: "Registrer",
    sale: "Salg",
    tax: "Skat",
    item: "Vare",
    description: "Beskrivelse",
    completed: "Fuldført",
    overdue: "Overskredet",
    today: "I dag",
    tomorrow: "I morgen",
    recurring: "Tilbagevendende",
    daily: "Dagligt",
    weekly: "Ugentligt",
    monthly: "Månedligt",
    yearly: "Årligt",
    day: "Dag",
    week: "Uge",
    month: "Måned",
    year: "År",
    subject: "Emne",
    message: "Besked",
    sent: "Sendt",
    drafts: "Kladder",
    enable: "Aktivér",
    disable: "Deaktivér",
    on: "Til",
    off: "Fra",
    loading: "Indlæser",
    info: "Info!",
    confirmation: "Bekræftelse",
    yes: "Ja",
    no: "Nej",
    hello: "Hej!",
    name: "Navn",
    invoice: "Faktura",
    quote: "Tilbud",
    offer: "Tilbud",
    lead: "Lead",
    customer: "Kunde",
    admin: "Admin",
    employee: "Medarbejder",
    currency: "Valuta",
    invoice_list: "Faktura liste",
    add_new_invoice: "Tilføj ny faktura",
    record_payment: "Registrer betaling",
    quote_list: "Tilbudsliste",
    add_new_quote: "Tilføj nyt tilbud",
    offer_list: "Tilbudsliste",
    add_new_offer: "Tilføj nyt tilbud",
    lead_list: "Lead liste",
    add_new_lead: "Tilføj nyt lead",
    customer_list: "Kundeliste",
    add_new_customer: "Tilføj ny kunde",
    payment_list: "Betalingsliste",
    add_new_payment: "Tilføj ny betaling",
    settings_list: "Indstillingsliste",
    add_new_settings: "Tilføj nye indstillinger",
    admin_list: "Admin liste",
    add_new_admin: "Tilføj ny admin",
    employee_list: "Medarbejderliste",
    add_new_employee: "Tilføj ny medarbejder",
    currency_list: "Valutaliste",
    add_new_currency: "Tilføj ny valuta",
    payment_mode: "Betalingsmåde",
    payment_mode_list: "Betalingsmåde liste",
    add_new_payment_mode: "Tilføj ny betalingsmåde",
    email_template: "Email skabelon",
    email_template_list: "Email skabelon liste",
    add_new_email_template: "Tilføj ny email skabelon",
    product_list: "Produktliste",
    add_new_product: "Tilføj nyt produkt",
    order_list: "Ordreliste",
    add_new_order: "Tilføj ny ordre",
    general_settings: "Generelle indstillinger",
    email_templates: "E-mail-skabeloner",
    birthday: "Fødselsdag",
    birthplace: "Fødested",
    department: "Afdeling",
    gender: "Køn",
    position: "Stilling",
    accepted: "Accepteret",
    add_field: "Tilføj felt",
    advanced_settings: "Avancerede indstillinger",
    after: "Efter",
    allowed_role: "Tilladt rolle",
    app_name: "App-navn",
    app_settings: "App-indstillinger",
    available_variables: "Tilgængelige variabler",
    back: "Tilbage",
    balance: "Balance",
    before: "Før",
    body: "Krop",
    cent_precision: "Cent nøjagtighed",
    client: "Klient",
    company: "Firma",
    company_settings: "Firmaets indstillinger",
    convert_to_invoice: "Konverter til faktura",
    crm_settings: "CRM-indstillinger",
    currency_format: "Valutaformat",
    currency_name: "Valutanavn",
    currency_position: "Valutaposition",
    currency_symbol: "Valutasymbol",
    decimal_separator: "Decimal separator",
    default_mode: "Standardtilstand",
    delete_confirmation: "Slet bekræftelse",
    download_pdf: "Download PDF",
    draft: "Udkast",
    email_content: "E-mail-indhold",
    enabled: "Aktiveret",
    expire_date: "Udløbsdato",
    finance_settings: "Finansindstillinger",
    first_name: "Fornavn",
    interested: "Interesseret",
    last_name: "Efternavn",
    manager_first_name: "Manager fornavn",
    manager_last_name: "Manager efternavn",
    money_format_settings: "Pengeformatindstillinger",
    new: "Ny",
    not_interested: "Ikke interesseret",
    note: "Note",
    number: "Nummer",
    password: "Adgangskode",
    payment_information: "Betalingsoplysninger",
    photo: "Foto",
    position_in_company: "Position i firmaet",
    preview: "Forhåndsvisning",
    profile_settings: "Profilindstillinger",
    reached: "Nået",
    recent_invoices: "Seneste fakturaer",
    recent_quotes: "Seneste tilbud",
    reference: "Reference",
    refresh: "Opdater",
    role: "Rolle",
    select_language: "Vælg sprog",
    send_by_email: "Send via e-mail",
    setting: "Indstilling",
    setting_category: "Indstillingskategori",
    setting_name: "Indstillingsnavn",
    show: "Vis",
    show_invoice: "Vis faktura",
    sorry_the_page_you_requested_does_not_exist: "Beklager, den side, du har anmodet om, findes ikke",
    source: "Kilde",
    tax_total: "Skat i alt",
    thousand_separator: "Tusind separator",
    to_write_a_variable_name_use_the_convention: "For at skrive et variabelnavn, skal du bruge konventionen",
    total_paid: "Betalt i alt",
    total_remaining: "Resterende i alt",
    type: "Type",
    update_currency_format: "Opdater valutaformat",
    update_password: "Opdater adgangskode",
    update_your_company_informations: "Opdater dine firmaoplysninger",
    zero_format: "Nulformat",
    no_results_found: "Ingen resultater fundet",
    please_wait: "Vent venligst",
    are_you_sure: "Er du sikker?",
    confirm_password: "Bekræft adgangskode",
    current_password: "Nuværende adgangskode",
    new_password: "Ny adgangskode",
    remember_me: "Husk mig",
    thank_you_exclamation: "Tak!",
    welcome_back: "Velkommen tilbage!",
    payment_status: "Betalingsstatus",
    error_404: "Fejl 404 - Siden blev ikke fundet",
    error_500: "Fejl 500 - Intern serverfejl",
    sub_total: "Subtotal",
    are_you_sure_you_want_to_delete: "Er du sikker på, at du vil slette : ",
    core_setting: "Kerneindstilling",
    created: "Oprettet",
    created_by: "Oprettet af",
    credit: "Kredit",
    currency_settings: "Valutaindstillinger",
    declined: "Afvist",
    default: "Standard",
    due_balance: "Forfalden saldo",
    expired: "Udløbet",
    expired_date: "Udløbsdato",
    invoices_preview: "Faktura forhåndsvisning",
    not_paid: "Ikke betalt",
    offers_preview: "Tilbud forhåndsvisning",
    paid: "Betalt",
    partially: "Delvist",
    payments_preview: "Betalinger forhåndsvisning",
    quotes_preview: "Tilbud forhåndsvisning",
    template: "Skabelon",
    this_month: "Denne måned",
    unpaid: "Ubetalte",
    value: "Værdi",
    active_customer: "Aktiv kunde",
    customer_preview: "Kunde forhåndsvisning",
    new_customer_this_month: "Ny kunde denne måned",
    already_have_account_login: "Har allerede en konto Login",
    forgot_password: "Glemt adgangskode",
    log_in: "Log ind",
    or: "Eller",
    sign_in: "Log ind",
    sign_up: "Tilmeld dig",
    select_date: "Vælg dato",
    men: "Mænd",
    women: "Kvinder",
    click_to_upload: "Klik for at uploade",
    admin_super_admin: "Admin (Super Admin)",
    staff_admin_crud: "Personale Admin (Opret, Læs, Opdater, Slet)",
    staff_cru: "Personale (Opret, Læs, Opdater)",
    create_and_read_only: "Opret og læs kun",
    read_only: "Kun læse",
    register_now: "Tilmeld dig nu",
    forget_password: "Glemt kodeord",
    request_new_password: "Anmod om nyt kodeord",
    manage_your_company_with: "Administrer din virksomhed med",
    all_in_one_tool: "Alt-i-en værktøj",
    easily_add_and_manage_your_services: "Tilføj og administrer nemt dine tjenester",
    it_brings_together_your_invoice_clients_and_leads: "Det samler dine fakturaklienter og leads",
    run_and_scale_your_erp_crm_apps: "Kør og skalér dine ERP CRM-apps",
    people: "Mennesker",
    customize_this_application: "Tilpas denne ansøgning",
    do_you_need_help_on_customize_of_this_app: "Har du brug for hjælp til at tilpasse denne app",
    contact_us: "Kontakt os",
    company_logo: "Virksomhedslogo",
    application_name: "Ansøgningsnavn",
    allow_registration: "Tillad registrering",
    application_email: "Ansøgnings-e-mail",
    application_url: "Ansøgnings-URL",
    mutli_branch: "Flere filialer",
    company_name: "Firmanavn",
    company_address: "Firmaadresse",
    company_state: "Firmastate",
    company_country: "Firma land",
    company_email: "Firma e-mail",
    company_phone: "Firma telefon",
    company_cell: "Firma mobil",
    company_website: "Firma hjemmeside",
    company_tax_number: "Firma skatte nummer",
    company_vat_number: "Firma momsnummer",
    company_reg_number: "Firma registreringsnummer",
    update_company_logo: "Opdater virksomhedslogo",
    update_company_finance_settings: "Opdater virksomhedsfinansindstillinger",
    last_invoice_number: "Sidste fakturanummer",
    last_quote_number: "Sidste tilbudsnummer",
    last_offer_number: "Sidste tilbudsnummer",
    last_payment_number: "Sidste betalingsnummer",
    invoice_prefix: "Faktura præfiks",
    quote_prefix: "Tilbud præfiks",
    offer_prefix: "Tilbud præfiks",
    payment_prefix: "Betalings præfiks",
    current_invoice_year: "Nuværende fakturaår",
    current_quote_year: "Nuværende tilbudsår",
    current_offer_year: "Nuværende tilbudsår",
    algeria: "Algeriet",
    company_list: "Virksomhedsliste",
    add_new_company: "Tilføj ny virksomhed",
    contact: "Kontakt",
    website: "Hjemmeside",
    france: "Frankrig",
    afghanistan: "Afghanistan",
    albania: "Albanien",
    andorra: "Andorra",
    angola: "Angola",
    anguilla: "Anguilla",
    argentina: "Argentina",
    armenia: "Armenien",
    aruba: "Aruba",
    australia: "Australien",
    austria: "Østrig",
    azerbaijan: "Aserbajdsjan",
    bahamas: "Bahamas",
    bahrain: "Bahrain",
    bangladesh: "Bangladesh",
    barbados: "Barbados",
    belarus: "Hviderusland",
    belgium: "Belgien",
    belize: "Belize",
    benin: "Benin",
    bermuda: "Bermuda",
    bhutan: "Bhutan",
    bolivia: "Bolivia",
    bosnia_and_herzegovina: "Bosnien-Hercegovina",
    botswana: "Botswana",
    brazil: "Brasilien",
    brunei_darussalam: "Brunei Darussalam",
    bulgaria: "Bulgarien",
    burkina_faso: "Burkina Faso",
    burundi: "Burundi",
    cabo_verde: "Cabo Verde",
    cambodia: "Cambodja",
    cameroon: "Cameroun",
    canada: "Canada",
    central_african_republic: "Centralafrikanske Republik",
    chad: "Tchad",
    chile: "Chile",
    china: "Kina",
    colombia: "Colombia",
    comoros: "Comorerne",
    congo: "Congo",
    costa_rica: "Costa Rica",
    croatia: "Kroatien",
    cuba: "Cuba",
    cyprus: "Cypern",
    czechia: "Tjekkiet",
    cote_d_ivoire: "Elfenbenskysten",
    denmark: "Danmark",
    djibouti: "Djibouti",
    dominica: "Dominica",
    dominican_republic: "Den Dominikanske Republik",
    ecuador: "Ecuador",
    egypt: "Egypten",
    el_salvador: "El Salvador",
    eritrea: "Eritrea",
    estonia: "Estland",
    eswatini: "Eswatini",
    ethiopia: "Etiopien",
    fiji: "Fiji",
    finland: "Finland",
    gabon: "Gabon",
    gambia: "Gambia",
    georgia: "Georgien",
    germany: "Tyskland",
    ghana: "Ghana",
    gibraltar: "Gibraltar",
    greece: "Grækenland",
    greenland: "Grønland",
    guadeloupe: "Guadeloupe",
    guam: "Guam",
    guatemala: "Guatemala",
    guernsey: "Guernsey",
    guinea: "Guinea",
    guinea_bissau: "Guinea-Bissau",
    guyana: "Guyana",
    haiti: "Haiti",
    honduras: "Honduras",
    hong_kong: "Hongkong",
    hungary: "Ungarn",
    iceland: "Island",
    india: "Indien",
    indonesia: "Indonesien",
    iran: "Iran",
    iraq: "Irak",
    ireland: "Irland",
    israel: "Israel",
    italy: "Italien",
    jamaica: "Jamaica",
    japan: "Japan",
    jordan: "Jordan",
    kazakhstan: "Kasakhstan",
    kenya: "Kenya",
    korea: "Korea",
    kuwait: "Kuwait",
    kyrgyzstan: "Kirgisistan",
    latvia: "Letland",
    lebanon: "Libanon",
    lesotho: "Lesotho",
    liberia: "Liberia",
    libya: "Libyen",
    liechtenstein: "Liechtenstein",
    lithuania: "Litauen",
    luxembourg: "Luxembourg",
    madagascar: "Madagaskar",
    malawi: "Malawi",
    malaysia: "Malaysia",
    maldives: "Maldiverne",
    mali: "Mali",
    malta: "Malta",
    martinique: "Martinique",
    mauritania: "Mauretanien",
    mauritius: "Mauritius",
    mexico: "Mexico",
    moldova: "Moldova",
    monaco: "Monaco",
    mongolia: "Mongoliet",
    montenegro: "Montenegro",
    montserrat: "Montserrat",
    morocco: "Marokko",
    mozambique: "Mozambique",
    myanmar: "Myanmar",
    namibia: "Namibia",
    nepal: "Nepal",
    netherlands: "Holland",
    new_caledonia: "Ny Kaledonien",
    new_zealand: "New Zealand",
    nicaragua: "Nicaragua",
    niger: "Niger",
    nigeria: "Nigeria",
    norway: "Norge",
    oman: "Oman",
    pakistan: "Pakistan",
    palestine: "Palæstina",
    panama: "Panama",
    papua_new_guinea: "Papua Ny Guinea",
    paraguay: "Paraguay",
    peru: "Peru",
    philippines: "Filippinerne",
    poland: "Polen",
    portugal: "Portugal",
    puerto_rico: "Puerto Rico",
    qatar: "Qatar",
    macedonia: "Makedonien",
    romania: "Rumænien",
    russia: "Rusland",
    rwanda: "Rwanda",
    r_union: "Reunion",
    saudi_arabia: "Saudi-Arabien",
    senegal: "Senegal",
    serbia: "Serbien",
    singapore: "Singapore",
    slovakia: "Slovakiet",
    slovenia: "Slovenien",
    somalia: "Somalia",
    south_africa: "Sydafrika",
    south_sudan: "Sydsudan",
    spain: "Spanien",
    sri_lanka: "Sri Lanka",
    sudan: "Sudan",
    suriname: "Surinam",
    sweden: "Sverige",
    switzerland: "Schweiz",
    syria: "Syrien",
    taiwan: "Taiwan",
    tajikistan: "Tadsjikistan",
    tanzania: "Tanzania",
    thailand: "Thailand",
    timor_leste: "Timor-Leste",
    togo: "Togo",
    tonga: "Tonga",
    tunisia: "Tunesien",
    turkey: "Tyrkiet",
    turkmenistan: "Turkmenistan",
    uganda: "Uganda",
    ukraine: "Ukraine",
    united_arab_emirates: "De Forenede Arabiske Emirater",
    united_kingdom: "Storbritannien",
    united_states: "USA",
    uruguay: "Uruguay",
    uzbekistan: "Usbekistan",
    venezuela: "Venezuela",
    vietnam: "Vietnam",
    yemen: "Yemen",
    zambia: "Zambia",
    zimbabwe: "Zimbabwe",
    person: "Person",
    people_list: "Menneskeliste",
    add_new_person: "Tilføj ny person",
    firstname: "Fornavn",
    lastname: "Efternavn",
    client_list: "Klientliste",
    add_new_client: "Tilføj ny klient",
    idurar_app_title: "Open Source ERP CRM Fakturering Regnskab Udgifter",
    verify_your_account: "Verificer din konto",
    complete_verification_by_providing_the_code_that_you_received_by_email: "Fuldfør verifikationen ved at angive koden, som du har modtaget via e-mail",
    verify_now: "Verificer nu",
    expense: "Udgift",
    product_category: "Produktkategori",
    expenses_category: "Udgiftskategori",
    taxes: "Skat",
    product_category_list: "Produktkategori liste",
    add_new_product_category: "Tilføj ny produktkategori",
    color: "Farve",
    expense_list: "Udgiftsliste",
    add_new_expense: "Tilføj ny udgift",
    expense_category: "Udgiftskategori",
    ref: "Reference",
    expense_category_list: "Udgiftskategoriliste",
    add_new_expense_category: "Tilføj ny udgiftskategori",
    taxes_list: "Skatteliste",
    add_new_tax: "Tilføj ny skat",
    date_format: "Datoformat",
    server_url: "Server URL",
    }
     export default lang