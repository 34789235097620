import {fetchData,postData,updateData } from '../utils/apiUtils';
export const get_labor_submitted_attendance= async (project,date) => {
    const url = `project/labor_attendance/get/submittedsheet?project=${encodeURIComponent(project)}`;
    const responseData = await fetchData(url);
    return responseData;
  };
  
  export const get_labor_attendance_byids= async (Ids) => {
    const url = `project/labor_attendance/get/attendancebyids?ids=${encodeURIComponent(Ids)}`;
    const responseData = await fetchData(url);
    return responseData;
  };

  export const get_labor_processed_attendance= async (project) => {
    const url = `project/labor_attendance/get/processedattendance?project=${encodeURIComponent(project)}`;
    const responseData = await fetchData(url);
    return responseData;
  };

  export const acceptOrRejectedAtendance= async (AttendanceData) => {
    const url = `project/labor_attendance/update/attendance_status`;
    const responseData = await updateData(url, AttendanceData);
    return responseData;
  };

  export const ApproveLaborAttendancePayment = async (projectData) => {
    const url = `project/labor_attendance_record/registerpayment`;
    const responseData = await postData(url, projectData);
    return responseData;
  };

  export const RejectLaborAttendance = async (projectData) => {
    const url = `project/labor_attendance/reject_labor_attendance`;
    const responseData = await updateData(url,projectData);
    return responseData;
  };

  export const SetLaborPaymentPaid = async (paymentStatus) => {
    const url = `project/labor_attendance_record/update`;
    const responseData = await updateData(url, paymentStatus);
    return responseData;
  };